import { initializeApp } from 'firebase/app'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import firebaseCredentials from './credentials'

const app = initializeApp(firebaseCredentials)
const db = getFirestore(app)
const auth = getAuth(app)

signInAnonymously(auth)
	.then(() => {
		console.log('Signed in anonymously')
	})
	.catch(error => {
		console.error('Error signing in anonymously:', error)
	})

export { auth, db }
