import { Listbox, ListboxButton, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'

import clsx from 'clsx'

type Option = {
	label: string
	subtitle?: string
	value: string
}

interface ListBoxProps {
	options: Option[]
	selectedOption: string
	className?: string
	onChange: (value: string) => void
}

export const ListBox = ({ options, selectedOption, className, onChange }: ListBoxProps) => {
	return (
		<div className={className}>
			<Listbox value={selectedOption} onChange={onChange}>
				{({ open }) => (
					<>
						<div className="relative mt-1">
							<ListboxButton
								className={clsx(
									'relative border cursor-pointer border-[#13BAB4] w-full rounded pl-4 pr-10 text-left md:shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm',
									selectedOption ? 'py-1' : 'py-3',
									open ? 'bg-[#13BAB40F]' : 'bg-white'
								)}>
								<span className="flex flex-col gap-y-0.5 truncate text-sm">
									{options.find(option => option.value === selectedOption)?.label}
									<span className="text-tertiary text-xs">
										{options.find(option => option.value === selectedOption)?.subtitle}
									</span>
								</span>
								<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="19"
										height="18"
										viewBox="0 0 19 18"
										fill="none">
										<path
											d="M9.50072 10.9963C9.41033 10.9963 9.3262 10.9819 9.24833 10.953C9.17044 10.9242 9.0964 10.8747 9.02622 10.8045L5.65555 7.43381C5.5517 7.32997 5.49857 7.19945 5.49617 7.04224C5.49376 6.88502 5.54688 6.75209 5.65555 6.64344C5.7642 6.53479 5.89592 6.48047 6.05072 6.48047C6.20552 6.48047 6.33725 6.53479 6.4459 6.64344L9.50072 9.69827L12.5555 6.64344C12.6594 6.53961 12.7899 6.48648 12.9471 6.48407C13.1043 6.48167 13.2372 6.53479 13.3459 6.64344C13.4546 6.75209 13.5089 6.88382 13.5089 7.03864C13.5089 7.19344 13.4546 7.32516 13.3459 7.43381L9.97523 10.8045C9.90504 10.8747 9.831 10.9242 9.75312 10.953C9.67524 10.9819 9.59111 10.9963 9.50072 10.9963Z"
											fill="#123258"
										/>
									</svg>
								</span>
							</ListboxButton>
							<Transition
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0">
								<Listbox.Options className="z-20 mt-[52px] max-h-60 w-full md:min-w-max overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
									{options.map(option => (
										<Listbox.Option
											key={option.value}
											className={({ active }) =>
												`relative cursor-default select-none py-2 pl-10 pr-4 ${
													active ? 'bg-[#13BAB40F] text-primary' : 'text-gray-900'
												}`
											}
											value={option.value}>
											{({ selected }) => (
												<>
													<span
														className={`truncate flex flex-col gap-y-0.5 ${
															selected ? 'font-medium' : 'font-normal'
														}`}>
														{option.label}
														{option.subtitle && (
															<span className="text-tertiary text-xs">{option.subtitle}</span>
														)}
													</span>
													{selected ? (
														<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-[#13BAB4]">
															<CheckIcon className="h-5 w-5" aria-hidden="true" />
														</span>
													) : null}
												</>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
		</div>
	)
}
