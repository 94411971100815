import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

import clsx from 'clsx'

interface SelectProps
	extends React.DetailedHTMLProps<
		React.SelectHTMLAttributes<HTMLSelectElement>,
		HTMLSelectElement
	> {
	children: React.ReactNode
	labelText?: string
	index?: string
	errors?: FieldErrors<FieldValues>
	name: string
	register?: UseFormRegister<any>
}

export const Select: React.FC<SelectProps> = ({
	labelText,
	errors,
	index,
	children,
	register,
	name,
	className,
	...restProp
}) => {
	const errorText = errors?.[name]?.message as string
	return (
		<div className={clsx('relative overflow-visible rounded-md', className)}>
			<label
				htmlFor="name"
				className="absolute -top-2.5 z-10 text-xs cursor-text px-0.5 text-primary bg-white left-3">
				{labelText}
			</label>
			<select
				{...restProp}
				{...(register?.(name) ?? {})}
				className={clsx(
					'font-medium w-full placeholder:text-tertiary focus:ring-0 border border-border bg-white focus:border-primary-light rounded-md md:py-3 text-sm outline-none',
					className?.includes('text') ? className : 'text-primary',
					className
				)}>
				{children}
			</select>
			{errorText && <p className="text-xs mt-1 text-red-500">{errorText}</p>}
		</div>
	)
}
