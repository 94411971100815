import axios from 'services/axios'

const getCompanyAdmins = (companyId: string): Promise<Admin[]> => {
	return axios.get(`/auth/company-admin/${companyId}`).then(response => response.data)
}

const createAdmin = (data: AdminForm) => {
	return axios.post('/auth/admin/signup', data).then(response => response.data)
}

const updateAdmin = (adminId: string, data: AdminForm) => {
	return axios.put(`/auth/admin/${adminId}`, data).then(response => response.data)
}

const adminService = {
	getCompanyAdmins,
	createAdmin,
	updateAdmin
}

export default adminService
