import { PencilIcon } from '@heroicons/react/24/outline'

import clsx from 'clsx'
import { Status, mappedStatuses } from 'constants/constants'

interface CategoryCardProps {
	id: string
	name: string
	color: string
	isActive: string
	onEdit: (id: string) => void
}

export const CategoryCard = ({ id, name, color, isActive, onEdit }: CategoryCardProps) => {
	return (
		<div className="md:p-5 max-md:w-full max-md:py-3 max-md:px-2 md:pb-6 min-w-[225px] rounded-xl bg-white flex md:flex-col max-md:justify-between md:items-center gap-y-2 md:gap-y-5">
			<div className="max-md:hidden flex items-center justify-between w-full">
				<p
					className={clsx(
						'py-0.5 px-5 rounded-full capitalize text-white text-sm font-semibold',
						isActive === 'aktiv' ? 'bg-[#61BC5F]' : 'bg-[#F84C6B]'
					)}>
					{mappedStatuses[isActive as Status]}
				</p>
				<PencilIcon
					onClick={() => onEdit(id)}
					className="h-5 w-5 cursor-pointer text-tertiary hover:text-primary"
				/>
			</div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="47"
				height="43"
				viewBox="0 0 47 43"
				fill="none"
				className="max-md:hidden">
				<g opacity="0.7">
					<rect
						x="0.203674"
						y="0.777344"
						width="26.6667"
						height="26.6667"
						rx="13.3333"
						fill="#13BAB4"
						fillOpacity="0.3"
					/>
					<path
						d="M13.7522 26.0234C13.2889 28.8344 13.8702 31.6477 15.2334 34.0086C16.7655 36.6617 19.285 38.7445 22.4209 39.6133"
						stroke="#123258"
						strokeWidth="1.5"
						strokeMiterlimit="10"
						strokeLinejoin="round"
					/>
					<path
						d="M33.4468 17.19L35.5979 20.916"
						stroke="#123258"
						strokeWidth="1.5"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M19.9362 13.7734L25.705 3.78125L29.9311 11.1012"
						stroke="#123258"
						strokeWidth="1.5"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M17.3019 9.21094L12.1147 18.1953C10.9622 20.1919 11.1061 22.438 12.1147 24.1849C13.1234 25.9319 14.9965 27.1797 17.3019 27.1797C19.6074 27.1797 21.4804 25.9319 22.4891 24.1849C23.4977 22.438 23.6418 20.1919 22.4891 18.1953L17.3019 9.21094Z"
						stroke="#123258"
						strokeWidth="1.5"
						strokeMiterlimit="10"
						strokeLinejoin="round"
					/>
					<path
						d="M40.9385 17.4007C41.881 21.1725 41.7878 24.5013 41.494 26.8798C40.2539 28.3619 39.0139 29.844 37.7737 31.3261C38.8337 31.2509 39.8936 31.1756 40.9535 31.1003C40.6586 31.7272 36.4115 40.4137 28.274 41.0117C25.9856 41.1799 23.9976 40.6638 22.4932 40.0537C21.9973 37.3861 22.5921 34.58 23.8722 32.1881C24.4608 31.0856 25.1881 30.0678 26.0143 29.1304C26.4106 30.3246 26.807 31.5189 27.2034 32.7132C27.8036 30.5957 28.4039 28.4782 29.0041 26.3608C30.4786 25.554 32.3144 24.3403 34.1228 22.5378C37.4097 19.2614 38.9393 15.7009 39.6539 13.5812C40.1282 14.6919 40.5805 15.9678 40.9385 17.4007Z"
						stroke="#123258"
						strokeWidth="1.5"
						strokeMiterlimit="10"
						strokeLinejoin="round"
					/>
					<path
						d="M15.0891 42.2188C17.2657 41.8938 19.8126 41.2625 22.493 40.0539C31.5663 35.9629 35.8903 28.0885 37.4141 24.9002"
						stroke="#123258"
						strokeWidth="1.5"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M32.4702 14.1455C32.4702 14.577 32.1204 14.9268 31.689 14.9268C31.2575 14.9268 30.9077 14.577 30.9077 14.1455C30.9077 13.714 31.2575 13.3643 31.689 13.3643C32.1204 13.3643 32.4702 13.714 32.4702 14.1455Z"
						fill="#123258"
					/>
				</g>
			</svg>
			<div className="flex items-start md:items-center gap-x-2">
				<div style={{ backgroundColor: color }} className="h-4 w-4 rounded" />
				<h3 className="text-primary max-md:hidden font-semibold">{name}</h3>
				<div className="flex flex-col gap-y-2 md:hidden">
					<h3 className="text-primary text-sm font-semibold">{name}</h3>
					<p
						className={clsx(
							'text-xs font-semibold',
							isActive === Status.ACTIVE ? 'text-[#13BAB4]' : 'text-[#F84C6B]'
						)}>
						{mappedStatuses[isActive as Status]}
					</p>
				</div>
			</div>
			<PencilIcon
				onClick={() => onEdit(id)}
				className="h-5 w-5 md:hidden cursor-pointer text-tertiary hover:text-primary self-center"
			/>
		</div>
	)
}
