import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { Language } from 'constants/constants'
import deTranslations from 'locales/de/translate.json'
import enTranslations from 'locales/en/translate.json'

i18next
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		fallbackLng: (code: string) =>
			code ? (code.includes(Language.DE) ? Language.DE : Language.EN) : Language.EN,
		ns: ['translation'],
		resources: {
			en: enTranslations,
			de: deTranslations
		},
		keySeparator: '.',
		interpolation: {
			escapeValue: false
		},
		react: {
			nsMode: 'default',
			useSuspense: false
		},
		returnObjects: true,
		debug: process.env.NODE_ENV === 'development'
	})

export default i18next
