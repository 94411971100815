import axios from 'services/axios'

const getCompanyCategories = (): Promise<Category[]> => {
	return axios.get('/company/categories').then(response => response.data)
}

const getCompanyCategoriesForEmployee = (): Promise<Category[]> => {
	return axios.get('/employee/categories').then(response => response.data)
}

const getCategoryById = (categoryId: string): Promise<Category> => {
	return axios.get(`/categories/${categoryId}`).then(response => response.data)
}

const createCategory = (data: CategoryForm) => {
	return axios.post('/categories', data).then(response => response.data)
}

const updateCategory = (categoryId: string, data: CategoryForm) => {
	return axios.put(`/categories/${categoryId}`, data).then(response => response.data)
}

const deleteCategory = (categoryId: string) => {
	return axios.delete(`/categories/${categoryId}`).then(response => response.data)
}

const categoryService = {
	getCompanyCategories,
	getCompanyCategoriesForEmployee,
	getCategoryById,
	createCategory,
	updateCategory,
	deleteCategory
}

export default categoryService
