import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { useAppSelector } from 'hooks'
import { Admins } from 'pages/admins'
import { Appointments } from 'pages/appointments'
import { AppointmentDetails } from 'pages/appointments/details'
import { Auth } from 'pages/auth'
import { Calendar } from 'pages/calendar'
import { ChangePassword } from 'pages/change-password'
import { Chats } from 'pages/chats'
import { Clients } from 'pages/clients'
import { ClientDetails } from 'pages/clients/details'
import { Dashboard } from 'pages/dashboard'
import { Events } from 'pages/events'
import { Licenses } from 'pages/licenses'
import { LicenseStatus } from 'pages/licenses/status'
import { Categories } from 'pages/medicine/categories'
import { Questionnaire } from 'pages/medicine/questionnaire'
import { Services } from 'pages/medicine/services'
import { CompanyProfile } from 'pages/settings/company/profile'
import { Employee } from 'pages/settings/employees'
import { Faqs } from 'pages/settings/faqs'
import { SubCompanies } from 'pages/sub-companies'
import { CreateSubCompany } from 'pages/sub-companies/create'
import { EditSubCompany } from 'pages/sub-companies/edit'
import { MobileSupport } from 'pages/support'
import { TaskManager } from 'pages/task-manager'
import { CreateTask } from 'pages/task-manager/create'
import { useEffect, useState } from 'react'

const Routing = () => {
	const auth = useAppSelector(state => state.auth)

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/auth"
					element={
						<PublicRoute auth={auth}>
							<Auth />
						</PublicRoute>
					}
				/>
				<Route
					path="/"
					element={
						<ProtectedRoute
							redirectPath="/calendar"
							customRedirect={auth.is_adminUser === false}
							auth={auth}>
							<Dashboard />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/calendar"
					element={
						<ProtectedRoute auth={auth}>
							<Calendar />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/events"
					element={
						<ProtectedRoute auth={auth}>
							<Events />
						</ProtectedRoute>
					}
				/>
				<Route path="/subcompanies">
					<Route path="company">
						<Route
							index
							element={
								<ProtectedRoute
									auth={auth}
									customRedirect={!auth.roles?.includes('ROLE_SUPERADMIN')}>
									<SubCompanies />
								</ProtectedRoute>
							}
						/>
						<Route
							path="create"
							element={
								<ProtectedRoute
									auth={auth}
									customRedirect={!auth.roles?.includes('ROLE_SUPERADMIN')}>
									<CreateSubCompany />
								</ProtectedRoute>
							}
						/>
						<Route
							path="edit/:id"
							element={
								<ProtectedRoute
									auth={auth}
									customRedirect={!auth.roles?.includes('ROLE_SUPERADMIN')}>
									<EditSubCompany />
								</ProtectedRoute>
							}
						/>
					</Route>

					<Route
						path="admins"
						element={
							<ProtectedRoute auth={auth} customRedirect={!auth.roles?.includes('ROLE_SUPERADMIN')}>
								<Admins />
							</ProtectedRoute>
						}
					/>
				</Route>
				<Route
					path="/chats"
					element={
						<ProtectedRoute auth={auth}>
							<Chats />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/appointments"
					element={
						<ProtectedRoute auth={auth}>
							<Appointments />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/appointment-details/:id"
					element={
						<ProtectedRoute auth={auth}>
							<AppointmentDetails />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/clients"
					element={
						<ProtectedRoute auth={auth}>
							<Clients />
						</ProtectedRoute>
					}
				/>

				<Route path="/task-manager">
					<Route
						index
						element={
							<ProtectedRoute auth={auth}>
								<TaskManager />
							</ProtectedRoute>
						}
					/>
					<Route
						path="create/:id?"
						element={
							<ProtectedRoute auth={auth}>
								<CreateTask />
							</ProtectedRoute>
						}
					/>
				</Route>
				<Route
					path="/client-details/:id"
					element={
						<ProtectedRoute auth={auth}>
							<ClientDetails />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/change-password"
					element={
						<ProtectedRoute auth={auth}>
							<ChangePassword />
						</ProtectedRoute>
					}
				/>
				<Route path="/settings">
					<Route
						path="company"
						element={
							<ProtectedRoute
								redirectPath="/calendar"
								customRedirect={auth.is_adminUser === false}
								auth={auth}>
								<CompanyProfile />
							</ProtectedRoute>
						}
					/>
					<Route
						path="faqs"
						element={
							<ProtectedRoute
								redirectPath="/calendar"
								customRedirect={auth.is_adminUser === false}
								auth={auth}>
								<Faqs />
							</ProtectedRoute>
						}
					/>
					<Route
						path="employees"
						element={
							<ProtectedRoute
								redirectPath="/calendar"
								customRedirect={auth.is_adminUser === false}
								auth={auth}>
								<Employee />
							</ProtectedRoute>
						}
					/>
					<Route path="licenses">
						<Route
							index
							element={
								<ProtectedRoute auth={auth}>
									<Licenses />
								</ProtectedRoute>
							}
						/>
						<Route
							path="status"
							element={
								<ProtectedRoute auth={auth}>
									<LicenseStatus />
								</ProtectedRoute>
							}
						/>
					</Route>
				</Route>
				<Route path="/tools">
					<Route
						path="categories"
						element={
							<ProtectedRoute
								redirectPath="/calendar"
								customRedirect={auth.is_adminUser === false}
								auth={auth}>
								{<Categories />}
							</ProtectedRoute>
						}
					/>
					<Route
						path="services"
						element={
							<ProtectedRoute
								redirectPath="/calendar"
								customRedirect={auth.is_adminUser === false}
								auth={auth}>
								{<Services />}
							</ProtectedRoute>
						}
					/>
					<Route
						path="questionnaire"
						element={
							<ProtectedRoute
								redirectPath="/calendar"
								customRedirect={auth.is_adminUser === false}
								auth={auth}>
								{<Questionnaire />}
							</ProtectedRoute>
						}
					/>
				</Route>
				<Route path="/support" element={<MobileSupport />} />
			</Routes>
		</BrowserRouter>
	)
}

type RouteProps = {
	auth?: AuthState
	redirectPath?: string
	children: JSX.Element
	customRedirect?: boolean
}

const ProtectedRoute = ({ auth, children, customRedirect, redirectPath = '/auth' }: RouteProps) => {
	if (!auth?.accessToken || customRedirect) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}

const PublicRoute = ({ auth, children, redirectPath = '/' }: RouteProps) => {
	const [shouldRedirect, setShouldRedirect] = useState(false)

	useEffect(() => {
		if (auth?.accessToken) {
			const timeout = setTimeout(() => {
				setShouldRedirect(true)
			}, 1000)
			return () => clearTimeout(timeout)
		}
	}, [auth])

	if (shouldRedirect) {
		return <Navigate to={redirectPath} replace />
	}

	return children
}

export default Routing
