import axios from 'services/axios';

const getAppointmentNotes = (
	clientId?: string,
	appointmentId?: string
): Promise<{ count: number; notices: Note[] }> => {
	const queryParams = new URLSearchParams()

	if (appointmentId) {
		queryParams.append('id_appointment', appointmentId)
	}
	if (clientId) {
		queryParams.append('client_id', clientId)
	}

	const queryString = queryParams.toString()

	return axios.get(`appointment/company/notice?${queryString}`).then(response => response.data)
}

const getClientNotes = (clientId: string): Promise<{ count: number; notices: Note[] }> => {
	return axios
		.get(`/appointment/company/notice?id_client=${clientId}`)
		.then(response => response.data)
}

const createNote = (data: Omit<Note, '_id' | 'id_company' | 'createdBy'> & { select: string }) => {
	return axios.post('/notice', data).then(response => response.data)
}

const updateNote = (
	noteId: string,
	data: Omit<Note, '_id' | 'id_company' | 'createdBy'> & { select: string }
) => {
	return axios.put(`/notice/${noteId}`, data).then(response => response.data)
}

const deleteNote = (noteId: string) => {
	return axios.delete(`/notice/${noteId}`).then(response => response.data)
}

const notesService = {
	getAppointmentNotes,
	getClientNotes,
	createNote,
	updateNote,
	deleteNote
}

export default notesService
