import axios from 'services/axios'

const getAllFaqs = (): Promise<Faq[]> => {
	return axios.get('/faqs').then(response => response.data)
}

const getFaqById = (faqId: string): Promise<Faq> => {
	return axios.get(`/faqs/${faqId}`).then(response => response.data)
}

const createFaq = (data: FaqForm) => {
	return axios.post('/faqs', data).then(response => response.data)
}

const updateFaq = (faqId: string, data: FaqForm) => {
	return axios.put(`/faqs/${faqId}`, data).then(response => response.data)
}

const deleteFaq = (faqId: string) => {
	return axios.delete(`/faqs/${faqId}`).then(response => response.data)
}

const faqsService = {
	getAllFaqs,
	getFaqById,
	createFaq,
	updateFaq,
	deleteFaq
}

export default faqsService
