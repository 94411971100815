import { InputHTMLAttributes } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

import clsx from 'clsx'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText?: string
	name: string
	index?: string
	register?: UseFormRegister<any>
	errors?: FieldErrors<FieldValues>
	className?: string
}

export const Input = ({ labelText, index, name, register, errors, ...props }: InputProps) => {
	const errorText = errors?.[name]?.message as string

	return (
		<div className="grow">
			<div className={clsx('relative overflow-visible rounded-md', props.className)}>
				<label
					htmlFor="name"
					className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
					{labelText}
				</label>
				<input
					{...(register?.(name) ?? {})}
					type="text"
					name={name}
					id={index}
					{...props}
					className={clsx(
						'w-full rounded font-normal py-3 bg-transparent focus:ring-0 border disabled:text-gray-500 focus:border-primary-light focus-visible:outline-none border-[#D3E3F1] text-primary placeholder-[#7F9AB2] placeholder:text-sm focus:outline-none text-sm',
						name === 'color' ? 'pl-8' : 'pl-4'
					)}
				/>
			</div>
			{errorText && <p className="text-xs text-red-500 mt-1">{errorText}</p>}
		</div>
	)
}
