import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'

import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { CounterInput } from 'components/inputs/counter-input'
import { useAppSelector } from 'hooks'
import { toast } from 'react-toastify'
import licenseService from 'services/license-service'
import { getTKey } from 'utils/language'

interface CreateLicenseProps {
	onCreation: () => void
	onCancel: () => void
}

type LicenseForm = {
	numLicenses: number
	months: number
}

export const CreateLicense = ({ onCreation, onCancel }: CreateLicenseProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('licenses.create')

	const auth = useAppSelector(state => state.auth)

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<LicenseForm>().shape({
		numLicenses: yup
			.number()
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.required(t(tKey('errors.numLicenses')))
			.min(1, t(tKey('errors.minLicenses'))),
		months: yup
			.number()
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.min(1, t(tKey('errors.minMonths')))
			.required(t(tKey('errors.months')))
	})

	const { handleSubmit, control } = useForm<LicenseForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const onSubmit = (data: LicenseForm) => {
		setIsLoading(true)
		const payload = {
			...data,
			companyId: auth.companyId
		}
		licenseService
			.createLicense(payload)
			.then(res => (window.location.href = res.url))
			.catch(err => toast.error(err?.response?.data?.message))
			.finally(() => setIsLoading(false))
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 py-6 max-md:px-5">
			<div className="flex flex-col gap-y-5">
				<div className="flex flex-col gap-y-5">
					<div className="flex flex-col gap-y-2">
						<label htmlFor="numLicenses" className="text-primary font-bold">
							{t(tKey('labels.numLicenses'))}
						</label>
						<Controller
							name="numLicenses"
							control={control}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<CounterInput
									value={value}
									onChange={event => {
										onChange(Number(event.target.value))
									}}
									controllerError={error}
									onNumberChange={number => onChange(number)}
									minimumValue={0}
								/>
							)}
						/>
					</div>
					<div className="flex flex-col gap-y-2">
						<label htmlFor="months" className="text-primary font-bold">
							{t(tKey('labels.months'))}
						</label>
						<Controller
							name="months"
							control={control}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<CounterInput
									value={value}
									onChange={event => {
										onChange(Number(event.target.value))
									}}
									controllerError={error}
									onNumberChange={number => onChange(number)}
									maxValue={12}
									minimumValue={0}
								/>
							)}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-y-6 mt-5">
					<div className="flex gap-x-6 justify-end">
						<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
							{t(tKey('buttons.cancel'))}
						</button>
						<Button disabled={isLoading} className="text-sm font-bold">
							{isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t(tKey('buttons.pleaseWait'))}
									</span>
								</div>
							) : (
								<span>{t(tKey('buttons.create'))}</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		</form>
	)
}
