import axios from 'services/axios'

const getDocumentsByAppointmentId = (appointmentId: string): Promise<UserDocument[]> => {
	return axios.get(`/files/appointment/${appointmentId}`).then(response => response.data.files)
}

const getGeneralDocumentsByClientId = (clientId: string): Promise<UserDocument[]> => {
	return axios.get(`/files/general/${clientId}`).then(response => response.data.files)
}

const getUserDocumentsByClientId = (clientId: string): Promise<UserDocument[]> => {
	return axios.get(`/files/${clientId}`).then(response => response.data.files)
}

const createUserDocument = (data: FormData) => {
	return axios
		.post('/files/general', data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const createAppointmentDocument = (data: FormData) => {
	return axios
		.post('/files/appointment', data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const deleteUserDocument = (id: string) => {
	return axios.delete(`/file/general/${id}`).then(response => response.data)
}

const deleteAppointmentDocument = (id: string) => {
	return axios.delete(`/file/appointment/${id}`).then(response => response.data)
}

const documentService = {
	getDocumentsByAppointmentId,
	getGeneralDocumentsByClientId,
	getUserDocumentsByClientId,
	createUserDocument,
	createAppointmentDocument,
	deleteUserDocument,
	deleteAppointmentDocument
}

export default documentService
