import axios from 'services/axios'

const getAppointmentTreatment = (appointmentId?: string): Promise<Note[]> => {
	const queryParams = new URLSearchParams()

	if (appointmentId) {
		queryParams.append('id_appointment', appointmentId)
	}

	const queryString = queryParams.toString()

	return axios.get(`appointment/company/treatment?${queryString}`).then(response => response.data)
}

const treatmentService = {
	getAppointmentTreatment
}

export default treatmentService
