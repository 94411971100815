import { Combobox, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import {
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	PlusIcon
} from '@heroicons/react/20/solid'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { ProfilePhotoIcon } from 'assets/icons'
import DayCalendar from 'components/calendar/day-calendar'
import MonthCalendar from 'components/calendar/month-calendar'
import WeekCalendar from 'components/calendar/week-calendar'
import { getAppLang, getTKey } from 'utils/language'

export interface CalendarEvent {
	id: string
	name: string
	description?: string
	startDateTime: string
	endDateTime: string
	color: string
	allDay: boolean
	type: 'appointment' | 'event' | 'task'
}

type View = 'week' | 'day' | 'month'

interface CalendarProps {
	events: CalendarEvent[]
	onUpdate: () => void
	view: View
	employees: Employee[]
	selectedEmployee: string
	setSelectedEmployee: (employeeId: string) => void
	currentDate: DateTime
	setCurrentDate: (dateTime: DateTime) => void
	setView: (view: View) => void
	refetch: () => void
	onEventClick: (eventId: string) => void
	onAppointmentClick: (appointmentId: string) => void
	openAppointmentPopup: () => void
	openEventPopup: () => void
}

const Calendar = ({
	events,
	onUpdate,
	onEventClick,
	currentDate,
	employees,
	selectedEmployee,
	setSelectedEmployee,
	setCurrentDate,
	view,
	setView,
	refetch,
	onAppointmentClick,
	openAppointmentPopup,
	openEventPopup
}: CalendarProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('calendar')

	const [query, setQuery] = useState('')
	const [isSticky, setIsSticky] = useState(false)

	const handlePrevious = () => {
		if (view === 'day') {
			setCurrentDate(currentDate.minus({ days: 1 }))
		} else if (view === 'week') {
			setCurrentDate(currentDate.minus({ weeks: 1 }))
		} else {
			setCurrentDate(currentDate.minus({ months: 1 }))
		}
	}

	const handleNext = () => {
		if (view === 'day') {
			setCurrentDate(currentDate.plus({ days: 1 }))
		} else if (view === 'week') {
			setCurrentDate(currentDate.plus({ weeks: 1 }))
		} else {
			setCurrentDate(currentDate.plus({ months: 1 }))
		}
	}

	const handleToday = () => {
		setCurrentDate(DateTime.now())
	}

	const filteredOptions = useMemo(() => {
		if (employees) {
			return employees.filter(employee => {
				const name = employee.fname + ' ' + employee.lname
				return (
					query === '' ||
					name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, '')) ||
					employee.city
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, '')) ||
					employee.zip
						.toString()
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return employees
		}
	}, [employees, query])

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsSticky(true)
			} else {
				setIsSticky(false)
			}
		}
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<div className="flex flex-col">
			<header className="flex md:sticky top-0 z-40 bg-white w-full self-start items-center justify-between md:border-b md:border-gray-200 px-4 md:px-6 py-4">
				<div className="flex items-center gap-x-3">
					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						onClick={handlePrevious}
						className="hidden cursor-pointer rounded-lg border border-[#CFD8DC] px-2 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">
						<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
					</div>
					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						onClick={handleNext}
						className="hidden cursor-pointer rounded-lg border border-[#CFD8DC] px-2 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">
						<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
					</div>
					<button
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						type="button"
						className="hidden border rounded-lg border-[#CFD8DC] px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
						onClick={handleToday}>
						{t(tKey('labels.today'))}
					</button>
					<h1 className="max-md:hidden text-base font-semibold leading-6 text-gray-900">
						<time dateTime={currentDate.setLocale(getAppLang()).toFormat('yyyy-LL')}>
							{view === 'day'
								? currentDate.setLocale(getAppLang()).toFormat('MMMM dd yyyy')
								: view === 'week'
									? `${currentDate.startOf('week').setLocale(getAppLang()).toFormat('MMMM dd')} - 
									${currentDate.endOf('week').setLocale(getAppLang()).toFormat('dd')}`
									: currentDate.setLocale(getAppLang()).toFormat('MMMM yyyy')}
						</time>
					</h1>
					<h1 className="md:hidden font-domine font-bold text-primary">
						{t(tKey('titles.calendar'))}
					</h1>
				</div>
				<div className="flex items-center">
					<Combobox value={selectedEmployee} onChange={setSelectedEmployee}>
						<div
							style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
							className="relative max-md:hidden mx-4">
							<div className="relative cursor-default w-full overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
								<Combobox.Input
									placeholder={t(tKey('labels.allEmployees'))}
									className="border border-[#CFD8DC] pr-7 w-full py-3 md:py-3 rounded-lg focus:border-secondary text-sm leading-5 text-gray-900 focus:ring-0"
									displayValue={option =>
										option
											? employees.find(employee => employee._id === selectedEmployee)?.fname +
												' ' +
												employees.find(employee => employee._id === selectedEmployee)?.lname
											: ''
									}
									onChange={event => setQuery(event.target.value)}
								/>
								<Combobox.Button className="absolute inset-y-0 flex items-center right-0 pr-2">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none">
										<path
											d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
											fill="#123258"
										/>
									</svg>
								</Combobox.Button>
							</div>
							<Transition
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0">
								<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
									{Array.isArray(employees) &&
										filteredOptions.map((option, index) => (
											<Combobox.Option
												key={index}
												className={({ active }) =>
													`relative cursor-default select-none py-0.5 ${
														active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
													}`
												}
												value={option._id}>
												{({ selected, active }) => (
													<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
														<img
															src={
																option.profile_url === 'crm' ? ProfilePhotoIcon : option.profile_url
															}
															className="h-6 w-6 rounded-full object-cover"
														/>
														<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow flex text-left rounded-md">
															{option.fname + ' ' + option.lname}

															<span className="text-tertiary text-xs">
																{option.city && option.zip ? `${option.city} - ${option.zip}` : ''}
															</span>
														</span>
													</span>
												)}
											</Combobox.Option>
										))}
								</Combobox.Options>
							</Transition>
						</div>
					</Combobox>
					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						className="flex max-md:hidden items-center gap-x-3 p-2 rounded-lg border border-[#CFD8DC]">
						<button
							style={
								view === 'day'
									? {
											boxShadow: '0px 4px 12px 0px #00000021'
										}
									: {}
							}
							onClick={() => setView('day')}
							className="rounded-lg py-1.5 px-3 text-sm">
							{t(tKey('labels.day'))}
						</button>
						<button
							style={
								view === 'week'
									? {
											boxShadow: '0px 4px 12px 0px #00000021'
										}
									: {}
							}
							onClick={() => setView('week')}
							className="rounded-lg py-1.5 px-3 text-sm">
							{t(tKey('labels.week'))}
						</button>
						<button
							style={
								view === 'month'
									? {
											boxShadow: '0px 4px 12px 0px #00000021'
										}
									: {}
							}
							onClick={() => setView('month')}
							className="rounded-lg py-1.5 px-3 text-sm">
							{t(tKey('labels.month'))}
						</button>
					</div>
					<div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
						<Menu as="div" className="relative md:hidden">
							<MenuButton
								style={{ boxShadow: '0px 6px 24px 0px #12325814' }}
								type="button"
								className="ml-4 py-1.5 px-1.5 flex gap-x-2 items-center bg-primary-light rounded text-sm font-semibold text-white focus-visible:outline-none">
								<PlusIcon className="h-4 w-4 text-white" aria-hidden="true" />
							</MenuButton>
							<MenuItems
								style={{ boxShadow: '0px 4px 24px 0px #12325829' }}
								className="absolute border ml-4 border-[#CFD8DC80] right-0 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								<MenuItem>
									<button
										onClick={openEventPopup}
										className="px-3 hover:bg-primary-light hover:text-white w-full text-left py-2 border-b border-[#CFD8DC66] text-sm text-primary">
										{t(tKey('labels.event'))}
									</button>
								</MenuItem>
								<MenuItem>
									<button
										onClick={openAppointmentPopup}
										className="px-3 hover:bg-primary-light hover:text-white w-full text-left py-2 border-b border-[#CFD8DC66] text-sm text-primary">
										{t(tKey('labels.appointment'))}
									</button>
								</MenuItem>
							</MenuItems>
						</Menu>
					</div>

					<Menu as="div" className="relative">
						<Menu.Button
							style={{ boxShadow: '0px 6px 24px 0px #12325814' }}
							type="button"
							className="ml-4 py-2 px-2 flex gap-x-2 items-center bg-primary-light max-md:hidden rounded text-sm font-semibold text-white focus-visible:outline-none">
							<PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
							{t(tKey('labels.create'))}
							<ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
						</Menu.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95">
							<Menu.Items
								style={{ boxShadow: '0px 4px 24px 0px #12325829' }}
								className="absolute border ml-4 border-[#CFD8DC80] right-0 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								<Menu.Item>
									<button
										onClick={openEventPopup}
										className="px-3 hover:bg-primary-light hover:text-white w-full text-left py-2 border-b border-[#CFD8DC66] text-sm text-primary">
										{t(tKey('labels.event'))}
									</button>
								</Menu.Item>
								<Menu.Item>
									<button
										onClick={openAppointmentPopup}
										className="px-3 hover:bg-primary-light hover:text-white w-full text-left py-2 border-b border-[#CFD8DC66] text-sm text-primary">
										{t(tKey('labels.appointment'))}
									</button>
								</Menu.Item>
							</Menu.Items>
						</Transition>
					</Menu>
				</div>
			</header>
			<div className={clsx('md:hidden bg-white sticky top-0 z-30', { ' py-4': isSticky })}>
				<Combobox value={selectedEmployee} onChange={setSelectedEmployee}>
					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						className="relative md:hidden w-fit mx-4 mb-5">
						<div className="relative cursor-default w-full overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
							<Combobox.Input
								placeholder={t(tKey('labels.allEmployees'))}
								className="border border-[#CFD8DC] pr-7 w-full py-2 md:py-3 rounded-lg focus:border-secondary text-sm leading-5 text-gray-900 focus:ring-0"
								displayValue={option =>
									option
										? employees.find(employee => employee._id === selectedEmployee)?.fname +
											' ' +
											employees.find(employee => employee._id === selectedEmployee)?.lname
										: ''
								}
								onChange={event => setQuery(event.target.value)}
							/>
							<Combobox.Button className="absolute inset-y-0 flex items-center right-0 pr-2">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none">
									<path
										d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
										fill="#123258"
									/>
								</svg>
							</Combobox.Button>
						</div>
						<Transition
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
								{Array.isArray(employees) &&
									filteredOptions.map((option, index) => (
										<Combobox.Option
											key={index}
											className={({ active }) =>
												`relative cursor-default select-none py-0.5 ${
													active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
												}`
											}
											value={option._id}>
											{({ selected, active }) => (
												<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
													<img
														src={
															option.profile_url === 'crm' ? ProfilePhotoIcon : option.profile_url
														}
														className="h-6 w-6 object-cover rounded-full"
													/>
													<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
														{option.fname + ' ' + option.lname}

														<span className="text-tertiary text-xs">
															{option.city && option.zip ? `${option.city} - ${option.zip}` : ''}
														</span>
													</span>
												</span>
											)}
										</Combobox.Option>
									))}
							</Combobox.Options>
						</Transition>
					</div>
				</Combobox>
				<div className="flex md:hidden -mt-3 px-4 justify-between items-center">
					<div className="flex items-center gap-x-3.5">
						<div className="flex gap-x-1 items-center ml-auto">
							<div
								style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
								onClick={handlePrevious}
								className="md:hidden cursor-pointer rounded-lg border border-[#CFD8DC] px-2 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative">
								<ChevronLeftIcon className="h-3 w-3" aria-hidden="true" />
							</div>
							<div
								style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
								onClick={handleNext}
								className="md:hidden cursor-pointer rounded-lg border border-[#CFD8DC] px-2 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative">
								<ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
							</div>
						</div>
						<h1 className="md:hidden text-xs text-primary font-semibold leading-4 text-center">
							<time dateTime={currentDate.setLocale(getAppLang()).toFormat('yyyy-LL')}>
								{view === 'day'
									? currentDate.toFormat('MMMM dd yyyy')
									: view === 'week'
										? `${currentDate.startOf('week').setLocale(getAppLang()).toFormat('MMMM dd')} - 
									${currentDate.endOf('week').setLocale(getAppLang()).toFormat('dd')}`
										: currentDate.setLocale(getAppLang()).toFormat('MMMM yyyy')}
							</time>
						</h1>
					</div>
					<Menu as="div" className="md:hidden relative w-fit">
						<MenuButton
							type="button"
							style={{ boxShadow: '0px 4px 12px 0px #00000021' }}
							className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs text-primary ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
							{view === 'day'
								? t(tKey('labels.daily'))
								: view === 'week'
									? t(tKey('labels.weekly'))
									: t(tKey('labels.monthly'))}
							<ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
						</MenuButton>
						<MenuItems className="absolute right-0 mt-2 w-full min-w-max origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<MenuItem>
								{({ active }) => (
									<button
										className={`${
											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
										} group flex w-full items-center px-4 py-2 text-xs`}
										onClick={() => setView('day')}>
										{t(tKey('labels.daily'))}
									</button>
								)}
							</MenuItem>
							<MenuItem>
								{({ active }) => (
									<button
										className={`${
											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
										} group flex w-full items-center px-4 py-2 text-xs`}
										onClick={() => setView('week')}>
										{t(tKey('labels.weekly'))}
									</button>
								)}
							</MenuItem>
							<MenuItem>
								{({ active }) => (
									<button
										className={`${
											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
										} group flex w-full items-center px-4 py-2 text-xs`}
										onClick={() => setView('month')}>
										{t(tKey('labels.monthly'))}
									</button>
								)}
							</MenuItem>
						</MenuItems>
					</Menu>
				</div>
			</div>
			<div className="flex m-2 flex-auto">
				{view === 'day' && (
					<DayCalendar
						refetch={refetch}
						onAppointmentClick={onAppointmentClick}
						onEventClick={onEventClick}
						currentDate={currentDate}
						events={events}
						onUpdate={onUpdate}
					/>
				)}
				{view === 'week' && (
					<WeekCalendar
						onDateClick={(date: DateTime) => {
							setCurrentDate(date)
							setView('day')
						}}
						refetch={refetch}
						onAppointmentClick={onAppointmentClick}
						onEventClick={onEventClick}
						currentDate={currentDate}
						events={events}
						onUpdate={onUpdate}
					/>
				)}
				{view === 'month' && (
					<MonthCalendar
						onEventClick={onEventClick}
						refetch={refetch}
						onAppointmentClick={onAppointmentClick}
						onDateClick={(date: DateTime) => {
							setCurrentDate(date)
							setView('day')
						}}
						currentDate={currentDate}
						events={events}
						onUpdate={onUpdate}
					/>
				)}
			</div>
		</div>
	)
}

export default Calendar
