import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { Modal } from 'components/app/modal'
import { CalendarEvent } from 'components/calendar'
import HourlySlot from 'components/calendar/day-calendar/hourly-slot'
import TimeBasedEventCard from 'components/calendar/day-calendar/time-based-event-card'
import { EventDetail } from 'components/calendar/event-detail'
import { Hours } from 'constants/calendar'
import { useComponentVisible } from 'hooks/useComponentVisible'
import useIsMobile from 'hooks/useIsMobile'
import { getAppLang } from 'utils/language'

import '../scroll-bar.css'
import './day-calendar.css'

interface ViewProps {
	currentDate: DateTime
	refetch: () => void
	onAppointmentClick: (appointmentId: string) => void
	onEventClick: (eventId: string) => void
	events: CalendarEvent[]
	onUpdate: () => void
}

const DayCalendar = ({
	currentDate,
	events,
	onUpdate,
	refetch,
	onAppointmentClick,
	onEventClick
}: ViewProps) => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [isDragging, setIsDragging] = useState(false)

	const container = useRef<HTMLDivElement>(null)
	const containerNav = useRef<HTMLDivElement>(null)
	const containerOffset = useRef<HTMLDivElement>(null)

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
	const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>()
	const [openNotePopup, setOpenNotePopup] = useState(false)
	const [openAppointmentPopup, setOpenAppointmentPopup] = useState(false)

	useEffect(() => {
		const currentMinute = new Date().getHours() * 60
		if (container.current && containerNav.current && containerOffset.current) {
			container.current.scrollTop =
				((container.current.scrollHeight -
					containerNav.current.offsetHeight -
					containerOffset.current.offsetHeight) *
					currentMinute) /
				1440
		}
	}, [])

	const calculateOverlappingEvents = (events: CalendarEvent[]) => {
		const sortedEvents = events
			.filter(event => DateTime.fromISO(event.startDateTime).hasSame(currentDate, 'day'))
			.sort(
				(a, b) =>
					DateTime.fromISO(a.startDateTime).toMillis() -
					DateTime.fromISO(b.startDateTime).toMillis()
			)

		const overlappingGroups: CalendarEvent[][] = []
		let currentGroup: CalendarEvent[] = []
		let latestEndTime: DateTime | null = null

		for (let i = 0; i < sortedEvents.length; i++) {
			const currentEvent = sortedEvents[i]
			const currentStartTime = DateTime.fromISO(currentEvent.startDateTime)
			const currentEndTime = DateTime.fromISO(currentEvent.endDateTime)

			if (currentGroup.length === 0) {
				currentGroup.push(currentEvent)
				latestEndTime = currentEndTime
			} else {
				if (currentStartTime < (latestEndTime as DateTime)) {
					currentGroup.push(currentEvent)
					if (currentEndTime > (latestEndTime as DateTime)) {
						latestEndTime = currentEndTime
					}
				} else {
					overlappingGroups.push(currentGroup)
					currentGroup = [currentEvent]
					latestEndTime = currentEndTime
				}
			}
		}

		if (currentGroup.length > 0) {
			overlappingGroups.push(currentGroup)
		}

		return overlappingGroups
	}

	const allDayEvents = events.filter(
		event => event.allDay && DateTime.fromISO(event.startDateTime).hasSame(currentDate, 'day')
	)
	const timeSpecificEvents = events.filter(event => !event.allDay)

	const overlappingGroups = calculateOverlappingEvents(timeSpecificEvents)

	const handleEventSelection = (event: CalendarEvent, date: string) => {
		setSelectedEvent(event)
		setIsComponentVisible(true)
	}

	return (
		<div className="flex w-full h-full flex-col">
			<div ref={ref}>
				{((isComponentVisible && selectedEvent && !isMobile) ||
					openNotePopup ||
					openAppointmentPopup) && (
					<EventDetail
						{...selectedEvent}
						openNotePopup={openNotePopup}
						setOpenNotePopup={(value: boolean) => {
							setOpenNotePopup(value)
							setIsComponentVisible(true)
						}}
						openAppointmentPopup={openAppointmentPopup}
						setOpenAppointmentPopup={(value: boolean) => {
							setOpenAppointmentPopup(value)
							setIsComponentVisible(true)
						}}
						refetch={() => {
							setSelectedEvent(undefined)
							setIsComponentVisible(false)
							refetch()
						}}
						onAppointmentClick={(id: string) => {
							setIsComponentVisible(false)
							onAppointmentClick(id)
						}}
						onEventClick={(id: string) => {
							setIsComponentVisible(false)
							onEventClick(id)
						}}
					/>
				)}
				{selectedEvent && isMobile && (
					<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
						<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
							<ChevronLeftIcon
								onClick={() => setSelectedEvent(undefined)}
								className="w-3 h-3 md:hidden cursor-pointer"
							/>
							<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
								{t('calendar.eventDetails.labels.eventDetails')}
							</h1>
							<div className="md:hidden" />
							<XMarkIcon
								onClick={() => setSelectedEvent(undefined)}
								className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
							/>
						</div>
						<div className="p-5">
							<EventDetail
								{...selectedEvent}
								openNotePopup={openNotePopup}
								setOpenNotePopup={setOpenNotePopup}
								openAppointmentPopup={openAppointmentPopup}
								setOpenAppointmentPopup={(value: boolean) => {
									setOpenAppointmentPopup(value)
									setIsComponentVisible(true)
								}}
								refetch={() => {
									setSelectedEvent(undefined)
									refetch()
								}}
								onAppointmentClick={(id: string) => {
									setIsComponentVisible(false)
									onAppointmentClick(id)
								}}
								onEventClick={(id: string) => {
									setIsComponentVisible(false)
									onEventClick(id)
								}}
							/>
						</div>
					</Modal>
				)}
			</div>
			{isComponentVisible && !isMobile && (
				<div className="fixed inset-0 bg-[#00000099] transition-opacity z-[99]" />
			)}
			<div className="isolate flex flex-auto bg-white">
				<div ref={container} className="flex flex-auto gap-5 flex-col">
					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						className="sticky top-[113px] md:top-[81px] z-30 flex-none bg-[#C6C7F8] rounded-lg py-2 border border-[#F5F7FA] shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
						<div className="-mr-px grid-cols-1 text-sm leading-6 text-black grid">
							<div
								key={currentDate.toISODate()}
								className="flex w-full items-center justify-center py-3">
								<span className="flex flex-row-reverse gap-2 items-center text-sm text-black">
									{currentDate.setLocale(getAppLang()).toFormat('cccc')}
									<span className="text-center text-[18px] font-semibold text-black">
										{currentDate.setLocale(getAppLang()).toFormat('d')}
									</span>
								</span>
							</div>
						</div>
					</div>
					{allDayEvents.length > 0 && (
						<div className="sticky top-[175px] md:top-[145px] z-30">
							<div
								style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
								className="flex items-center border border-[#CFD8DC80] bg-white rounded-xl w-full flex-auto">
								<div className="w-14 self-stretch rounded-tl-xl rounded-bl-xl flex-none bg-white ring-1 ring-gray-100" />
								<div>
									<div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
										All Day
									</div>
								</div>
								<div className="flex gap-x-1 w-full p-1">
									{allDayEvents.map(event => (
										<div
											key={event.id}
											onClick={() => handleEventSelection(event, currentDate.toISODate() as string)}
											className="cursor-pointer w-full gap-1 border-l-[1.2px] md:border-l-[3px] pl-2 pr-1 py-1.5 flex flex-col"
											style={{
												background: event.color + '10',
												borderColor: event.color,
												boxSizing: 'border-box',
												borderRadius: '4px'
											}}>
											<p className="text-primary font-semibold text-[10px] leading-[10px] md:text-sm md:leading-[18px]">
												{event.name}
											</p>
											<time
												dateTime={event.startDateTime}
												className="flex whitespace-nowrap items-center gap-[2.4px] md:gap-1.5 text-[#757575] text-[8px] md:text-xs">
												{t('calendar.eventDetails.labels.allDay')}
												<svg
													width="4"
													height="5"
													viewBox="0 0 4 5"
													fill="none"
													className="shrink-0"
													xmlns="http://www.w3.org/2000/svg">
													<circle cx="2" cy="2.55078" r="2" fill="#7F9AB2" />
												</svg>
												{(event as any).type === 'appointment'
													? (event as any).id_client.fname + ' ' + (event as any).id_client.lname
													: (event as any).type === 'event'
														? (event as any).addedBy.fname + ' ' + (event as any).addedBy.lname
														: (event as any).assignedBy.fname +
															' ' +
															(event as any).assignedBy.lname}
											</time>
										</div>
									))}
								</div>
							</div>
						</div>
					)}
					<div
						style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
						className="flex overflow-hidden -mt-2 border border-[#CFD8DC80] rounded-xl w-full flex-auto">
						<div className="w-14 rounded-tl-xl rounded-bl-xl  flex-none bg-white ring-1 ring-gray-100" />
						<div className="grid relative flex-auto grid-cols-1 grid-rows-1">
							{/* Horizontal lines */}
							<div
								className={clsx(
									'col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 day-grid',
									{
										'whole-day-grid':
											currentDate.weekdayShort === 'Sat' || currentDate.weekdayShort === 'Sun'
									}
								)}
								style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}>
								<div ref={containerOffset} className="row-end-1 h-7"></div>
								{Hours.map(hour => (
									<HourlySlot hour={hour} key={`hourly-slot-key-${hour}`} onUpdate={onUpdate} />
								))}
							</div>

							{/* Events */}
							{overlappingGroups.map((group, index) => (
								<div key={index} className="col-start-1 row-start-1 relative pointer-events-none">
									{group.map((event, subIndex) => (
										<TimeBasedEventCard
											event={event}
											handleEventSelection={handleEventSelection}
											groupLength={group.length}
											subIndex={subIndex}
											currentDate={currentDate}
											key={event.id}
											isDragging={isDragging}
											onUpdate={onUpdate}
											setIsDragging={setIsDragging}
										/>
									))}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DayCalendar
