export const Hours = [
	'00:00',
	'01:00',
	'02:00',
	'03:00',
	'04:00',
	'05:00',
	'06:00',
	'07:00',
	'08:00',
	'09:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
	'22:00',
	'23:00'
]

export const mappedHours = {
	'00:00': '12:00AM',
	'01:00': '1:00AM',
	'02:00': '2:00AM',
	'03:00': '3:00AM',
	'04:00': '4:00AM',
	'05:00': '5:00AM',
	'06:00': '6:00AM',
	'07:00': '7:00AM',
	'08:00': '8:00AM',
	'09:00': '9:00AM',
	'10:00': '10:00AM',
	'11:00': '11:00AM',
	'12:00': '12:00PM',
	'13:00': '1:00PM',
	'14:00': '2:00PM',
	'15:00': '3:00PM',
	'16:00': '4:00PM',
	'17:00': '5:00PM',
	'18:00': '6:00PM',
	'19:00': '7:00PM',
	'20:00': '8:00PM',
	'21:00': '9:00PM',
	'22:00': '10:00PM',
	'23:00': '11:00PM'
} as { [key: string]: string }
