import { Link } from 'react-router-dom'

import clsx from 'clsx'

interface StatsCardProps {
	title: string
	value: number
	total: number
	icon: React.ElementType
	link: string
	className?: string
}

export const StatsCard = ({ title, total, value, icon: Icon, link, className }: StatsCardProps) => {
	let percentage = (value / (total === 0 ? 1 : total)) * 100
	percentage =
		percentage % 1 !== 0 && percentage.toString().split('.')[1].length > 2
			? parseFloat(percentage.toFixed(2))
			: percentage
	return (
		<Link
			to={link}
			style={{
				background: 'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
			}}
			className="flex max-md:min-w-[193.5px] flex-col gap-3 rounded-[20px] px-3.5 pt-2 pb-3">
			<div className="flex flex-col gap-2">
				<div className="flex items-center justify-between">
					<Icon className="max-md:size-5" />
					<div className="flex items-center gap-1.5">
						<h1 className="font-domine text-xl md:text-[26px] text-white">{value}</h1>
						<div className="flex items-center justify-center px-0.5 h-6 rounded-full bg-[#FFFFFF3D]">
							<p className="font-medium text-white text-[10px]">{percentage}%</p>
						</div>
					</div>
				</div>
				<h1 className="text-[#FFFFFFCC] font-medium text-xs md:text-sm">{title}</h1>
			</div>
			<div className="overflow-hidden rounded-full bg-[#FFFFFF33]">
				<div style={{ width: `${percentage}%` }} className={clsx('h-1', className)} />
			</div>
		</Link>
	)
}
