import axios from 'services/axios'

const getCompanyQuestions = (group: string): Promise<AppointmentQuestion[]> => {
	return axios.get(`/groupissue/fields?group=${group}`).then(response => response.data)
}

const getQuestionById = (questionId: string): Promise<AppointmentQuestion> => {
	return axios.get(`/fields/${questionId}`).then(response => response.data)
}

const getQuestionsByServiceId = (
	serviceId: string,
	group: 'question'
): Promise<AppointmentQuestion[]> => {
	return axios.get(`/user/fields/${serviceId}?group=${group}`).then(response => response.data)
}

const updateQuestion = (questionId: string, data: QuestionForm) => {
	return axios.put(`/fields/${questionId}`, data).then(response => response.data)
}

const updateReports = (appointmentId: string, data: Object) => {
	return axios.put(`/appointments/${appointmentId}`, data).then(response => response.data)
}

const addCompanyQuestion = (data: QuestionForm) => {
	return axios.post('/fields', data).then(response => response.data)
}

const deleteQuestion = (questionId: string) => {
	return axios.delete(`/fields/${questionId}`).then(response => response.data)
}

const questionService = {
	getCompanyQuestions,
	getQuestionById,
	getQuestionsByServiceId,
	addCompanyQuestion,
	updateQuestion,
	updateReports,
	deleteQuestion
}

export default questionService
