import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

type Option = { name: string; href: string; current: boolean }

interface BreadcrumbProps {
	options: Option[]
	className?: string
}

export const Breadcrumb = ({ options, className }: BreadcrumbProps) => {
	return (
		<nav className={clsx('flex', className)} aria-label="Breadcrumb">
			<ol role="list" className="flex items-center gap-x-1">
				{options.map((option, index) => (
					<li key={option.name}>
						<div className="flex items-center">
							<Link
								to={option.href}
								className={clsx(
									'text-xs lg:text-sm font-medium text-primary',
									option.current ? 'opacity-100' : 'opacity-60'
								)}>
								{option.name}
							</Link>
							{index < options.length - 1 && (
								<ChevronRightIcon
									className="h-5 w-5 ml-1 flex-shrink-0 text-gray-400"
									aria-hidden="true"
								/>
							)}
						</div>
					</li>
				))}
			</ol>
		</nav>
	)
}
