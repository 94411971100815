import axios from 'services/axios'

const getAllEvents = (): Promise<CustomEvent[]> => {
	return axios.get('/events').then(response => response.data)
}

const getEventById = (eventId: string): Promise<CustomEvent> => {
	return axios.get(`/event/${eventId}`).then(response => response.data)
}

const getEventStatsByTime = (from?: number, to?: number): Promise<EventStat> => {
	const params = new URLSearchParams()

	if (from !== undefined) {
		params.append('from', from.toString())
	}
	if (to !== undefined) {
		params.append('to', to.toString())
	}

	const queryString = params.toString()
	const url = queryString ? `/events/stats?${queryString}` : '/events/stats'

	return axios.get(url).then(response => response.data)
}

const createEvent = (data: EventForm) => {
	return axios.post('/event', { ...data, eventType: 'event' }).then(response => response.data)
}

const updateEvent = (eventId: string, data: EventForm) => {
	return axios
		.put(`/event/${eventId}`, { ...data, eventType: 'event' })
		.then(response => response.data)
}

const deleteEvent = (eventId: string) => {
	return axios.delete(`/event/${eventId}`).then(response => response.data)
}

const eventService = {
	getAllEvents,
	getEventById,
	getEventStatsByTime,
	createEvent,
	updateEvent,
	deleteEvent
}

export default eventService
