import clsx from 'clsx'

interface AlertProps {
	content: string
	className?: string
}
export const Alert = ({ content, className }: AlertProps) => {
	return (
		<div
			className={clsx('rounded bg-[#D4EDDA] border border-[#C3E6CB] py-3 px-1 sm:px-6', className)}>
			<div className="flex items-center gap-x-2.5">
				<div className="flex-shrink-0">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="25"
						viewBox="0 0 24 25"
						className="max-sm:h-5 max-sm:w-5"
						fill="none">
						<path
							d="M10.5616 14.9923L8.06537 12.4962C7.97179 12.4026 7.85705 12.3526 7.72115 12.3462C7.58525 12.3397 7.4641 12.3897 7.3577 12.4962C7.25128 12.6026 7.19808 12.7205 7.19808 12.85C7.19808 12.9795 7.25128 13.0974 7.3577 13.2038L9.99615 15.8423C10.1577 16.0038 10.3462 16.0846 10.5616 16.0846C10.7769 16.0846 10.9654 16.0038 11.1269 15.8423L16.6039 10.3654C16.6974 10.2718 16.7474 10.1571 16.7538 10.0212C16.7603 9.88525 16.7103 9.7641 16.6039 9.6577C16.4974 9.55128 16.3795 9.49807 16.25 9.49807C16.1205 9.49807 16.0026 9.55128 15.8962 9.6577L10.5616 14.9923ZM12.0034 21.5C10.7588 21.5 9.58872 21.2638 8.4931 20.7915C7.39748 20.3192 6.44444 19.6782 5.63397 18.8685C4.82352 18.0588 4.18192 17.1066 3.70915 16.012C3.23638 14.9174 3 13.7479 3 12.5034C3 11.2588 3.23616 10.0887 3.70848 8.9931C4.18081 7.89748 4.82183 6.94444 5.63153 6.13398C6.44123 5.32353 7.39337 4.68192 8.48795 4.20915C9.58255 3.73638 10.7521 3.5 11.9966 3.5C13.2412 3.5 14.4113 3.73616 15.5069 4.20847C16.6025 4.68081 17.5556 5.32182 18.366 6.13152C19.1765 6.94122 19.8181 7.89337 20.2908 8.98795C20.7636 10.0826 21 11.2521 21 12.4966C21 13.7412 20.7638 14.9113 20.2915 16.0069C19.8192 17.1025 19.1782 18.0556 18.3685 18.866C17.5588 19.6765 16.6066 20.3181 15.512 20.7909C14.4174 21.2636 13.2479 21.5 12.0034 21.5ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5Z"
							fill="#155724"
						/>
					</svg>
				</div>
				<h3 className="text-[10px] sm:text-xs md:text-base font-bold text-[#155724]">{content}</h3>
			</div>
		</div>
	)
}
