import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { DateTime } from 'luxon'

import { Button } from 'components/app/button'
import { TimePicker } from 'components/app/time-picker'
import { AppointmentStatus, mappedAppointmentStatuses } from 'constants/constants'
import { CreateAppointment } from 'pages/appointments/create'
import appointmentService from 'services/appointment-service'
import medicalService from 'services/medical-service'
import { socket } from 'sockets/socket-context'
import { appendDateTime, getTimeRange } from 'utils/date'
import { getTKey } from 'utils/language'

interface CreateAppointmentFormProps {
	appointmentId: string | undefined
	createdClient?: string
	onClose: () => void
	onAddClient: () => void
	onCreation: () => void
}

export const CreateOrUpdateAppointmentForm = ({
	appointmentId,
	onClose,
	onAddClient,
	createdClient,
	onCreation
}: CreateAppointmentFormProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('calendar.createEvent')

	const [appointment, setAppointment] = useState<Appointment>()
	const [serviceSchedule, setServiceSchedule] = useState<Schedule[]>()
	const [forceRefresh, setForceRefresh] = useState(false)
	const [selectedTimeSlot, setSelectedTimeslot] = useState('')
	const [selectedDate, setSelectedDate] = useState('')

	useEffect(() => {
		if (appointmentId) {
			appointmentService.getAppointmentById(appointmentId).then(res => setAppointment(res))
		}
	}, [appointmentId, forceRefresh])

	useEffect(() => {
		if (appointment) {
			medicalService.getServiceSchedule(appointment.id_service).then(res => setServiceSchedule(res))
		}
	}, [appointment])

	const timeSlots = useMemo(() => {
		const mergedTime: Record<number, string[]> = {}
		for (const schedule of serviceSchedule ?? []) {
			if (schedule.type === 'Closed') continue

			const timeRange = getTimeRange(
				DateTime.fromObject(schedule.start).toMillis(),
				DateTime.fromObject(schedule.end).toMillis(),
				DateTime.fromObject(schedule.pause_start).toMillis(),
				DateTime.fromObject(schedule.pause_end).toMillis(),
				false,
				appointment?.service?.duration as string,
				schedule.day,
				Number(appointment?.service?.min_booking) / 60
			)

			if (timeRange) {
				Object.entries(timeRange).forEach(([key, value]) => {
					const day = parseInt(key)
					if (!mergedTime[day]) {
						mergedTime[day] = []
					}
					mergedTime[day].push(...value)
				})
			}
		}

		return mergedTime
	}, [serviceSchedule])

	const todayTimeSlots = useMemo(() => {
		for (const schedule of serviceSchedule ?? []) {
			if (schedule.type === 'Closed') return
			return getTimeRange(
				DateTime.fromObject(schedule.start).toMillis(),
				DateTime.fromObject(schedule.end).toMillis(),
				DateTime.fromObject(schedule.pause_start).toMillis(),
				DateTime.fromObject(schedule.pause_end).toMillis(),
				true,
				appointment?.service?.duration as string,
				DateTime.now().weekday === 7 ? 0 : DateTime.now().weekday,
				Number(appointment?.service?.min_booking) / 60
			)
		}
	}, [serviceSchedule])

	const onUpdateAppointment = () => {
		const startTime = appendDateTime(selectedDate, selectedTimeSlot)
		const endTime = appendDateTime(
			selectedDate,
			DateTime.fromFormat(selectedTimeSlot, 'HH:mm')
				.plus({
					minutes: Number(appointment?.service?.duration ?? 0)
				})
				.toFormat('HH:mm')
		)
		appointmentService
			.updateAppointmentSlots(appointment?._id as string, startTime, endTime)
			.then(res => {
				socket.emit('Appointment created', {
					appointment: res.data
				})
				toast.success(t(tKey('toast.updateAppointmentSuccess')))
				onCreation()
				setSelectedDate('')
				setSelectedTimeslot('')
			})
			.catch(err =>
				toast.error(err?.response?.data?.message ?? t(tKey('toast.updateAppointmentError')))
			)
	}

	const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		appointmentService
			.updateAppointment(appointmentId as string, { status: event.target.value })
			.then(res => {
				toast.success(t(tKey('toast.updateAppointmentSuccess')))
				socket.emit('Appointment created', {
					appointment: res.data
				})
				onCreation()
				setForceRefresh(prev => !prev)
			})
			.catch(err =>
				toast.error(err?.response?.data?.message ?? t(tKey('toast.updateAppointmentError')))
			)
	}

	return (
		<div className="flex overflow-auto md:fixed md:inset-y-0 md:right-0 md:z-[999] bg-white md:min-h-screen md:w-[360px] flex-col gap-y-3">
			<div className="flex max-md:hidden h-11 justify-between items-center py-1.5 px-4">
				<h1 className="font-domine font-bold leading-6 text-[#1C1C1C]">
					{appointmentId
						? t(tKey('headings.updateAppointment'))
						: t(tKey('headings.addAppointment'))}
				</h1>
				<XMarkIcon onClick={onClose} className="h-5 w-5 cursor-pointer text-[#1C1C1C]" />
			</div>
			{appointmentId && appointment ? (
				<>
					<div className="flex max-md:pt-10 pt-4 flex-col gap-y-6 px-3">
						<div className="relative overflow-visible rounded-md">
							<label
								htmlFor="name"
								className="absolute -top-2.5 z-10 text-xs cursor-text px-0.5 text-primary bg-white left-3">
								{t(tKey('labels.status'))}
							</label>
							<select
								className="font-medium w-full placeholder:text-tertiary focus:ring-0 border border-border bg-white focus:border-secondary rounded-md md:py-3 text-sm outline-none"
								name="appointment-status"
								value={appointment.status as AppointmentStatus}
								disabled={
									appointment.status === AppointmentStatus.COMPLETED ||
									appointment.status === AppointmentStatus.CANCELLED
								}
								onChange={handleStatusChange}>
								{Object.values(AppointmentStatus).map(status => (
									<option key={status} value={status}>
										{mappedAppointmentStatuses[status]}
									</option>
								))}
							</select>
						</div>
						<TimePicker
							companyId={appointment.id_company as any}
							duration={appointment?.service?.duration as string}
							timeSlots={timeSlots as { [day: number]: string[] }}
							todayTimeSlots={todayTimeSlots as { [day: number]: string[] }}
							selectedTimeSlot={selectedTimeSlot}
							setSelectedTimeSlot={setSelectedTimeslot}
							selectedDate={selectedDate as any}
							setSelectedDate={(date: string) => setSelectedDate(date)}
						/>
						<div className="py-3 flex justify-between gap-x-2">
							<Button
								disabled={!selectedTimeSlot}
								className="w-full rounded flex-1"
								onClick={onUpdateAppointment}
								style={!selectedTimeSlot ? { background: 'rgb(156 163 175)' } : {}}>
								{t(tKey('buttons.updateAppointment'))}
							</Button>
						</div>
					</div>
				</>
			) : (
				<CreateAppointment
					showInfo={false}
					className="!p-5 !pt-3"
					createdClient={createdClient}
					onAddClient={onAddClient}
					onConfirm={onCreation}
					onCancel={onClose}
				/>
			)}
		</div>
	)
}
