import {
	Disclosure,
	Listbox,
	ListboxButton,
	ListboxOption,
	ListboxOptions,
	Menu,
	Transition
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon, ChevronLeftIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'flowbite-react'
import { Fragment, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import {
	GreenFlagIcon,
	ProfilePhotoIcon,
	RedFlagIcon,
	SmallGreenFlagIcon,
	SmallRedFlagIcon,
	SmallWhiteFlagIcon,
	SmallYellowFlagIcon,
	WhiteFlagIcon,
	YellowFlagIcon
} from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Breadcrumb } from 'components/app/breadcrumb'
import { Button } from 'components/app/button'
import { AppLayout } from 'components/app/layout'
import { EllipsisMenu } from 'components/app/menu'
import { Language, TaskStatus } from 'constants/constants'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import employeeService from 'services/employee-service'
import taskService from 'services/task-service'
import { getAppLang, getTKey } from 'utils/language'

import { ComboSelector } from 'components/app/combo-selector'
import 'react-quill/dist/quill.snow.css'
import appointmentService from 'services/appointment-service'
import userService from 'services/user-service'
import './editor.css'

type AssignedBy = {
	_id: string
	fname: string
	lname: string
}

type Attachment = {
	name: string
	fileUrl: string
	_id: string
}

export const CreateTask = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isMobile = useIsMobile()

	const { id: taskId } = useParams()

	const tKey = getTKey('taskManager.create')
	const appLanguage = getAppLang()

	const auth = useAppSelector(state => state.auth)
	const { isSidebarVisible } = useAppSelector(state => state.sidebar)

	const [selectedStatus, setSelectedStatus] = useState(TaskStatus.NOTSTARTED)
	const [selectedTaskType, setSelectedTaskType] = useState('taskType')
	const [attachments, setAttachments] = useState<Attachment[] | File[]>([])
	const [selectedPriority, setSelectedPriority] = useState('')
	const [selectedDate, setSelectedDate] = useState('')
	const [selectedAssignee, setSelectedAssignee] = useState('')
	const [assignedBy, setAssignedBy] = useState<AssignedBy>({
		_id: auth._id,
		fname: auth.fname,
		lname: auth.lname
	})
	const [commentValue, setCommentValue] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [employees, setEmployees] = useState<Employee[]>([])
	const [companyAppointments, setCompanyAppointments] = useState<Appointment[]>([])
	const [selectedClient, setSelectedClient] = useState<string>('')
	const [selectedAppointment, setSelectedAppointment] = useState<string>('')
	const [users, setUsers] = useState<User[]>([])

	const schema = yup
		.object<{ taskName: string; comment?: string; description: string; subTasks: SubTask[] }>()
		.shape({
			taskName: yup.string().required(t(tKey('errors.taskName'))),
			description: yup.string().required(t(tKey('errors.description'))),
			subTasks: yup
				.array()
				.of(
					yup.object({
						taskName: yup.string().required(t(tKey('errors.taskName'))),
						description: yup.string().required(t(tKey('errors.description')))
					})
				)
				.notRequired()
		})

	const {
		register,
		handleSubmit,
		control,
		reset,
		formState: { errors }
	} = useForm<{ taskName: string; comment?: string; description: string; subTasks: SubTask[] }>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const { fields, append, remove } = useFieldArray({ name: 'subTasks', control })

	const statusOptions = [
		{ label: t(tKey('labels.backlog')), value: TaskStatus.NOTSTARTED },
		{ label: t(tKey('labels.inProgress')), value: TaskStatus.INPROGRESS },
		{ label: t(tKey('labels.complete')), value: TaskStatus.COMPLETED }
	]

	const taskOptions = ['taskType', 'client', 'customTask', 'appointment']

	useEffect(() => {
		if (auth.companyId) {
			if (auth.is_adminUser === true) {
				appointmentService
					.getCompanyAppointments(auth.companyId)
					.then(res => setCompanyAppointments(res))
			} else {
				appointmentService
					.getCompanyAppointments(auth.companyId)
					.then(res => setCompanyAppointments(res))
			}
		}

		userService.getCompanyUsers().then(res => {
			setUsers(res.users)
		})

		employeeService.getCompanyEmployees().then(employees => {
			if (taskId) {
				taskService.getTaskById(taskId).then(res => {
					reset({
						taskName: res.taskName,
						description: res.description,
						subTasks: res.subTasks
					})
					setSelectedPriority(res.priority)
					setSelectedStatus(res.status as TaskStatus)
					setSelectedAssignee(res.assignee._id)
					setAssignedBy(res.assignedBy)
					setSelectedTaskType(res.taskType === 'custom task' ? 'customTask' : res.taskType)
					setSelectedDate(DateTime.fromISO(res.taskDueDate).toFormat('dd.LL.yyyy'))
					setCommentValue(res.comment)
					setAttachments(res.attachments as Attachment[])
					setSelectedClient(res?.id_client?._id ?? '')
					setSelectedAppointment(res?.id_appointment?._id ?? '')
				})
			}
			setEmployees(employees)
		})
	}, [])

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return
		const files = Array.from(event.target.files)
		if (attachments.length >= 5 || files.length > 5)
			return toast.error(t(tKey('toast.maxFilesUpload')))
		setAttachments(prev => [...prev, ...files] as File[])
	}

	const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target

		if (value === '') {
			return setSelectedDate('')
		}
		const selectedDate = DateTime.fromISO(event.target.value)
			.setLocale(appLanguage)
			.toFormat('dd.LL.yyyy')
		setSelectedDate(selectedDate)
	}

	const onSubmit = (data: {
		taskName: string
		comment?: string
		description: string
		subTasks: SubTask[]
	}) => {
		if (!selectedPriority) return toast.warn(t(tKey('toast.selectPriority')))
		if (!selectedDate) return toast.warn(t(tKey('toast.selectDate')))
		if (!selectedTaskType || selectedTaskType === 'taskType') {
			return toast.warn(t(tKey('toast.selectTaskType')))
		}
		if (!selectedAssignee) return toast.warn(t(tKey('toast.selectAssignee')))
		if (selectedTaskType === 'client') {
			if (!selectedClient) return toast.warn(t(tKey('toast.selectClient')))
		}
		if (selectedTaskType === 'appointment') {
			if (!selectedAppointment) return toast.warn(t(tKey('toast.selectAppointment')))
		}

		setIsLoading(true)

		const form = {
			...data,
			assignee: selectedAssignee,
			assignedBy: assignedBy._id,
			taskType: selectedTaskType === 'customTask' ? 'custom task' : selectedTaskType,
			priority: selectedPriority,
			status: selectedStatus,
			taskDueDate: DateTime.fromFormat(selectedDate, 'dd.LL.yyyy').toISO() as string
		}

		const payload =
			selectedTaskType === 'client'
				? { ...form, id_client: selectedClient }
				: selectedTaskType === 'appointment'
					? { ...form, id_appointment: selectedAppointment }
					: form

		const formData = new FormData()
		for (const key in payload) {
			if (Object.prototype.hasOwnProperty.call(payload, key)) {
				if (key === 'subTasks') continue
				const value = (payload as { [key: string]: any })[key]
				formData.append(key, value)
			}
		}

		formData.append('subTasks', JSON.stringify(data.subTasks))

		if (attachments.length > 0) {
			attachments.map(attachment => {
				if (!('fileUrl' in attachment)) {
					formData.append('files', attachment as File)
				}
			})
		}

		if (commentValue) {
			formData.append('comment', commentValue)
		}

		if (taskId) {
			const previousAttachments = (attachments as Attachment[]).filter(
				attachment => 'fileUrl' in attachment
			)
			formData.append('attachments', JSON.stringify(previousAttachments))

			taskService
				.updateTask(taskId, formData)
				.then(() => {
					toast.success(t(tKey('toast.taskUpdationSuccess')))
					navigate('/task-manager')
				})
				.catch(() => toast.error(t(tKey('toast.taskUpdationError'))))
				.finally(() => setIsLoading(false))
		} else {
			taskService
				.createTask(formData)
				.then(() => {
					toast.success(t(tKey('toast.taskCreationSuccess')))
					navigate('/task-manager')
				})
				.catch(() => toast.error(t(tKey('toast.taskCreationError'))))
				.finally(() => setIsLoading(false))
		}
	}

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.taskManager'))}>
			<div className="px-5 py-4 items-center grid auto-cols-auto md:hidden grid-flow-col">
				<ChevronLeftIcon
					onClick={() => navigate(-1)}
					className="cursor-pointer w-4 h-4 text-primary"
				/>
				<h1 className="text-primary text-sm font-domine font-bold">
					{t(tKey('titles.taskManager'))}
				</h1>
			</div>
			<form
				className="md:h-[82vh] md:overflow-hidden self-stretch"
				onSubmit={handleSubmit(onSubmit)}>
				<div className="pt-6 max-md:pb-20 self-stretch grow h-full px-[15px] md:pt-[13px] pb-5 md:px-[30px] flex flex-col gap-y-4">
					<Breadcrumb
						className="max-md:hidden"
						options={[
							{
								name: t(tKey('labels.employeesTaskList')),
								href: '/task-manager',
								current: false
							},
							{
								name: t(tKey('labels.newTask')),
								href: `/task-manager/create`,
								current: true
							}
						]}
					/>
					<div className="flex max-md:flex-col self-stretch grow h-full max-md:gap-y-5 md:gap-x-[13px]">
						<div
							style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
							className="basis-full self-stretch grow h-full md:basis-[65%] rounded-xl flex flex-col gap-y-5">
							<div className="p-3 md:p-6 md:pb-16 md:overflow-y-auto flex flex-col gap-y-5 md:gap-y-8">
								<div className="flex justify-between items-center">
									<div className="flex items-center gap-x-7">
										<Listbox
											value={selectedStatus}
											onChange={value => setSelectedStatus(value as TaskStatus)}>
											{({ open }) => (
												<>
													<div className="relative mt-1">
														<ListboxButton
															className={clsx(
																'relative cursor-pointer text-white w-full flex justify-between items-center rounded font-bold text-left text-sm',
																{
																	'bg-red-500': selectedStatus === TaskStatus.NOTSTARTED,
																	'bg-[#207DD3]': selectedStatus === TaskStatus.INPROGRESS,
																	'bg-[#13BAB4]': selectedStatus === TaskStatus.COMPLETED
																}
															)}>
															<span className="truncate text-xs md:text-sm py-px md:py-1 pl-3 pr-3 md:pr-8 border-r border-white">
																{
																	statusOptions.find(option => option.value === selectedStatus)
																		?.label
																}
															</span>
															<span className="pointer-events-none py-0.5 md:py-1 flex items-center">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="25"
																	height="25"
																	viewBox="0 0 30 30"
																	className="max-md:w-5 max-md:h-5"
																	fill="none">
																	<path
																		d="M18.3269 15.0004C18.3269 15.1511 18.3029 15.2913 18.2548 15.4211C18.2067 15.5509 18.1242 15.6743 18.0072 15.7913L12.3894 21.409C12.2164 21.5821 11.9988 21.6707 11.7368 21.6747C11.4748 21.6787 11.2532 21.5902 11.0722 21.409C10.8911 21.228 10.8005 21.0084 10.8005 20.7504C10.8005 20.4924 10.8911 20.2729 11.0722 20.0918L16.1635 15.0004L11.0722 9.90905C10.8991 9.73599 10.8106 9.51845 10.8065 9.25643C10.8025 8.99443 10.8911 8.77288 11.0722 8.5918C11.2532 8.4107 11.4728 8.32014 11.7308 8.32014C11.9888 8.32014 12.2084 8.4107 12.3894 8.5918L18.0072 14.2096C18.1242 14.3266 18.2067 14.45 18.2548 14.5798C18.3029 14.7096 18.3269 14.8498 18.3269 15.0004Z"
																		fill="white"
																	/>
																</svg>
															</span>
														</ListboxButton>
														<Transition
															as={Fragment}
															leave="transition ease-in duration-100"
															leaveFrom="opacity-100"
															leaveTo="opacity-0">
															<ListboxOptions className="shadow-md z-20 py-2 flex flex-col mt-6 md:mt-9 rounded-md bg-white text-sm">
																<ListboxOption value="" disabled>
																	<p className="px-6 py-2 text-tertiary text-xs font-semibold">
																		{t(tKey('labels.status'))}
																	</p>
																</ListboxOption>
																{statusOptions.map(option => (
																	<ListboxOption
																		key={option.value}
																		className="relative hover:bg-[#13BAB40F] cursor-pointer flex select-none px-4 py-1.5"
																		value={option.value}>
																		{({ selected }) => (
																			<div className="flex gap-x-1.5 items-center md:gap-x-2.5">
																				<input
																					type="radio"
																					checked={selected}
																					className={clsx(
																						'focus:ring-0 max-md:w-3 max-md:h-3 border border-tertary',
																						{
																							'text-[#13BAB4]':
																								option.value === TaskStatus.COMPLETED,
																							'text-[#757575]':
																								option.value === TaskStatus.NOTSTARTED,
																							'text-[#207DD3]':
																								option.value === TaskStatus.INPROGRESS
																						}
																					)}
																				/>
																				<span className="w-full max-md:text-xs whitespace-nowrap font-semibold text-primary">
																					{option.label}
																				</span>
																				{selected && (
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="20"
																						height="20"
																						viewBox="0 0 18 18"
																						className="shrink-0 max-md:h-5 max-md:w-5"
																						fill="none">
																						<path
																							d="M7.16254 11.6362L13.6414 5.15733C13.7529 5.04579 13.8854 4.98881 14.0387 4.9864C14.1921 4.984 14.327 5.04098 14.4433 5.15733C14.5596 5.27366 14.6178 5.40731 14.6178 5.55828C14.6178 5.70923 14.5596 5.84288 14.4433 5.95923L7.63704 12.7655C7.50147 12.901 7.3433 12.9688 7.16254 12.9688C6.98177 12.9688 6.8236 12.901 6.68803 12.7655L3.55678 9.63423C3.44524 9.52269 3.39019 9.39024 3.39163 9.23688C3.39308 9.08351 3.45197 8.94866 3.56831 8.83233C3.68466 8.71598 3.81831 8.6578 3.96926 8.6578C4.12022 8.6578 4.25387 8.71598 4.37021 8.83233L7.16254 11.6362Z"
																							fill="#13BAB4"
																						/>
																					</svg>
																				)}
																			</div>
																		)}
																	</ListboxOption>
																))}
															</ListboxOptions>
														</Transition>
													</div>
												</>
											)}
										</Listbox>
										<Menu as="div" className="relative max-md:hidden">
											{({ open }) => (
												<>
													<Menu.Button className="flex gap-x-2.5 items-center">
														<img
															src={
																selectedPriority === 'high'
																	? RedFlagIcon
																	: selectedPriority === 'medium'
																		? YellowFlagIcon
																		: selectedPriority === 'low'
																			? GreenFlagIcon
																			: WhiteFlagIcon
															}
															className="border shrink-0 border-border rounded p-1.5"
															alt="priority-flag"
														/>

														{selectedPriority && (
															<span
																className={clsx('text-sm', {
																	'text-[#F84C6B]': selectedPriority === 'high',
																	'text-[#F5B715]': selectedPriority === 'medium',
																	'text-[#18DF80]': selectedPriority === 'low'
																})}>
																{t(tKey(`labels.${selectedPriority}`))}
															</span>
														)}
													</Menu.Button>
													{open && (
														<Transition
															as={Fragment}
															leave="transition ease-in duration-100"
															leaveFrom="opacity-100"
															leaveTo="opacity-0">
															<Menu.Items
																className={clsx(
																	'absolute pt-1 text-sm z-10 w-max mt-1 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg'
																)}
																static>
																<div>
																	<Menu.Item disabled>
																		<p className="px-6 py-2 text-tertiary text-xs font-semibold">
																			{t(tKey('labels.priority'))}
																		</p>
																	</Menu.Item>
																	{['low', 'medium', 'high'].map(option => (
																		<Menu.Item key={option}>
																			<button
																				className={clsx(
																					'group gap-x-2.5 font-semibold w-full text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left items-center rounded-md px-3 py-2',

																					{
																						'text-[#F84C6B]': option === 'high',
																						'text-[#F5B715]': option === 'medium',
																						'text-[#18DF80]': option === 'low'
																					}
																				)}
																				onClick={() => setSelectedPriority(option)}>
																				<img
																					src={
																						option === 'high'
																							? RedFlagIcon
																							: option === 'medium'
																								? YellowFlagIcon
																								: option === 'low'
																									? GreenFlagIcon
																									: WhiteFlagIcon
																					}
																					alt="priority-flag"
																				/>
																				<span>{t(tKey(`labels.${option}`))}</span>
																				{selectedPriority === option && (
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="20"
																						height="20"
																						viewBox="0 0 18 18"
																						className="shrink-0"
																						fill="none">
																						<path
																							d="M7.16254 11.6362L13.6414 5.15733C13.7529 5.04579 13.8854 4.98881 14.0387 4.9864C14.1921 4.984 14.327 5.04098 14.4433 5.15733C14.5596 5.27366 14.6178 5.40731 14.6178 5.55828C14.6178 5.70923 14.5596 5.84288 14.4433 5.95923L7.63704 12.7655C7.50147 12.901 7.3433 12.9688 7.16254 12.9688C6.98177 12.9688 6.8236 12.901 6.68803 12.7655L3.55678 9.63423C3.44524 9.52269 3.39019 9.39024 3.39163 9.23688C3.39308 9.08351 3.45197 8.94866 3.56831 8.83233C3.68466 8.71598 3.81831 8.6578 3.96926 8.6578C4.12022 8.6578 4.25387 8.71598 4.37021 8.83233L7.16254 11.6362Z"
																							fill="#13BAB4"
																						/>
																					</svg>
																				)}
																			</button>
																		</Menu.Item>
																	))}
																</div>
															</Menu.Items>
														</Transition>
													)}
												</>
											)}
										</Menu>

										<div className="relative max-md:hidden flex gap-x-2.5 items-center">
											<input
												type="date"
												id="date"
												min={new Date().toISOString().split('T')[0]}
												onChange={handleDateChange}
												className="py-1.5 date-filter opacity-0 w-full absolute z-30 input-cursor-pointer"
											/>
											<div className="border p-1.5 border-border rounded">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="22"
													height="22"
													viewBox="0 0 18 19"
													fill="none">
													<path
														d="M11.1635 14.1409C10.725 14.1409 10.351 13.986 10.0413 13.6764C9.73173 13.3668 9.57692 12.9928 9.57692 12.5543C9.57692 12.1159 9.73173 11.7418 10.0413 11.4322C10.351 11.1226 10.725 10.9678 11.1635 10.9678C11.6019 10.9678 11.976 11.1226 12.2856 11.4322C12.5952 11.7418 12.75 12.1159 12.75 12.5543C12.75 12.9928 12.5952 13.3668 12.2856 13.6764C11.976 13.986 11.6019 14.1409 11.1635 14.1409ZM4.21153 16.3909C3.86634 16.3909 3.57812 16.2752 3.34688 16.044C3.11563 15.8127 3 15.5245 3 15.1793V5.60239C3 5.2572 3.11563 4.96898 3.34688 4.73773C3.57812 4.50648 3.86634 4.39086 4.21153 4.39086H5.53845V2.71777H6.34614V4.39086H11.7115V2.71777H12.4616V4.39086H13.7885C14.1337 4.39086 14.4219 4.50648 14.6531 4.73773C14.8844 4.96898 15 5.2572 15 5.60239V15.1793C15 15.5245 14.8844 15.8127 14.6531 16.044C14.4219 16.2752 14.1337 16.3909 13.7885 16.3909H4.21153ZM4.21153 15.6409H13.7885C13.9038 15.6409 14.0096 15.5928 14.1058 15.4966C14.2019 15.4005 14.25 15.2947 14.25 15.1793V8.60239H3.75V15.1793C3.75 15.2947 3.79808 15.4005 3.89423 15.4966C3.99039 15.5928 4.09616 15.6409 4.21153 15.6409ZM3.75 7.85239H14.25V5.60239C14.25 5.48701 14.2019 5.38124 14.1058 5.28508C14.0096 5.18893 13.9038 5.14086 13.7885 5.14086H4.21153C4.09616 5.14086 3.99039 5.18893 3.89423 5.28508C3.79808 5.38124 3.75 5.48701 3.75 5.60239V7.85239Z"
														fill="#123258"
													/>
												</svg>
											</div>
											<input
												type="text"
												disabled
												value={selectedDate}
												className={clsx(
													'border-none mt-px p-0 font-semibold rounded h-8 text-primary placeholder:text-primary text-sm',
													selectedDate ? 'w-32' : appLanguage === Language.EN ? 'w-8' : 'w-8'
												)}
											/>
										</div>
									</div>
									<div className="flex items-end flex-col gap-x-2">
										<Menu as="div" className="relative rounded-xl inline-block text-left">
											{({ open }) => (
												<>
													<Menu.Button
														className={clsx(
															'border border-border max-md:text-xs flex justify-between w-full focus:border-secondary items-center gap-x-1 md:gap-x-5 text-primary text-sm rounded px-3 py-0.5 md:pl-4 md:pr-2 md:py-1 focus:outline-none',
															{
																'bg-[#06AB910F] border-secondary': open
															}
														)}>
														{t(tKey(`labels.${selectedTaskType}`))}

														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none">
															<path
																d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
																fill="#123258"
															/>
														</svg>
													</Menu.Button>
													{open && (
														<Transition
															as={Fragment}
															leave="transition ease-in duration-100"
															leaveFrom="opacity-100"
															leaveTo="opacity-0">
															<Menu.Items
																className={clsx(
																	'absolute pt-1 text-sm z-10 mt-1 origin-top-left w-max divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
																)}
																static>
																<div>
																	{taskOptions.map((option, index) => (
																		<Menu.Item key={option}>
																			<button
																				className={clsx(
																					'group text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left items-center rounded-md px-4 md:px-6 py-2'
																				)}
																				onClick={() => setSelectedTaskType(option)}>
																				{t(tKey(`labels.${option}`))}
																			</button>
																		</Menu.Item>
																	))}
																</div>
															</Menu.Items>
														</Transition>
													)}
												</>
											)}
										</Menu>
										{(selectedTaskType === 'client' || selectedTaskType === 'appointment') && (
											<ComboSelector
												placeholder={
													selectedTaskType === 'client'
														? t(tKey('labels.selectClient'))
														: t(tKey('labels.selectAppointment'))
												}
												options={
													selectedTaskType === 'client'
														? users.map(user => ({
																label: `${user.fname} ${user.lname}`,
																value: user._id
															}))
														: companyAppointments.map(appointment => ({
																label: `${appointment.service_name} - ${DateTime.fromMillis(
																	appointment.from
																).toFormat('dd LLL,yyyy')}`,
																detail: appointment.appointmentNumber,
																value: appointment._id
															}))
												}
												selectedItems={
													selectedTaskType === 'client'
														? selectedClient
															? users.find(user => user._id === selectedClient)?.fname +
																' ' +
																users.find(user => user._id === selectedClient)?.lname
															: ''
														: selectedAppointment
															? companyAppointments.find(
																	appointment => appointment._id === selectedAppointment
																)?.service_name +
																' - ' +
																DateTime.fromMillis(
																	Number(
																		companyAppointments.find(
																			appointment => appointment._id === selectedAppointment
																		)?.from
																	)
																).toFormat('dd LLL,yyyy')
															: ''
												}
												onChange={value =>
													selectedTaskType === 'client'
														? setSelectedClient(value)
														: setSelectedAppointment(value)
												}
											/>
										)}
									</div>
								</div>
								<div className="flex gap-x-4 items-start md:hidden">
									<Menu as="div" className="relative md:hidden">
										{({ open }) => (
											<>
												<Menu.Button className="flex flex-col gap-y-2 items-center">
													<img
														src={
															selectedPriority === 'high'
																? SmallRedFlagIcon
																: selectedPriority === 'medium'
																	? SmallYellowFlagIcon
																	: selectedPriority === 'low'
																		? SmallGreenFlagIcon
																		: SmallWhiteFlagIcon
														}
														className="border shrink-0 border-border rounded p-0.5"
														alt="priority-flag"
													/>

													{selectedPriority && (
														<span
															className={clsx('text-xs', {
																'text-[#F84C6B]': selectedPriority === 'high',
																'text-[#F5B715]': selectedPriority === 'medium',
																'text-[#18DF80]': selectedPriority === 'low'
															})}>
															{t(tKey(`labels.${selectedPriority}`))}
														</span>
													)}
												</Menu.Button>
												{open && (
													<Transition
														as={Fragment}
														leave="transition ease-in duration-100"
														leaveFrom="opacity-100"
														leaveTo="opacity-0">
														<Menu.Items
															className={clsx(
																'absolute pt-1 text-sm z-10 w-max mt-1 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg'
															)}
															static>
															<div>
																<Menu.Item disabled>
																	<p className="px-4 py-2 text-tertiary text-xs font-semibold">
																		{t(tKey('labels.priority'))}
																	</p>
																</Menu.Item>
																{['low', 'medium', 'high'].map(option => (
																	<Menu.Item key={option}>
																		<button
																			className={clsx(
																				'group gap-x-2 font-semibold w-full text-xs whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left items-center rounded-md px-3 py-2',

																				{
																					'text-[#F84C6B]': option === 'high',
																					'text-[#F5B715]': option === 'medium',
																					'text-[#18DF80]': option === 'low'
																				}
																			)}
																			onClick={() => setSelectedPriority(option)}>
																			<img
																				src={
																					option === 'high'
																						? SmallRedFlagIcon
																						: option === 'medium'
																							? SmallYellowFlagIcon
																							: option === 'low'
																								? SmallGreenFlagIcon
																								: SmallWhiteFlagIcon
																				}
																				alt="priority-flag"
																			/>
																			<span>{t(tKey(`labels.${option}`))}</span>
																			{selectedPriority === option && (
																				<svg
																					xmlns="http://www.w3.org/2000/svg"
																					width="18"
																					height="18"
																					viewBox="0 0 18 18"
																					className="shrink-0"
																					fill="none">
																					<path
																						d="M7.16254 11.6362L13.6414 5.15733C13.7529 5.04579 13.8854 4.98881 14.0387 4.9864C14.1921 4.984 14.327 5.04098 14.4433 5.15733C14.5596 5.27366 14.6178 5.40731 14.6178 5.55828C14.6178 5.70923 14.5596 5.84288 14.4433 5.95923L7.63704 12.7655C7.50147 12.901 7.3433 12.9688 7.16254 12.9688C6.98177 12.9688 6.8236 12.901 6.68803 12.7655L3.55678 9.63423C3.44524 9.52269 3.39019 9.39024 3.39163 9.23688C3.39308 9.08351 3.45197 8.94866 3.56831 8.83233C3.68466 8.71598 3.81831 8.6578 3.96926 8.6578C4.12022 8.6578 4.25387 8.71598 4.37021 8.83233L7.16254 11.6362Z"
																						fill="#13BAB4"
																					/>
																				</svg>
																			)}
																		</button>
																	</Menu.Item>
																))}
															</div>
														</Menu.Items>
													</Transition>
												)}
											</>
										)}
									</Menu>

									<div className="relative md:hidden flex flex-col gap-y-2 items-center">
										<input
											type="date"
											id="date"
											min={new Date().toISOString().split('T')[0]}
											onChange={handleDateChange}
											className="py-0 px-0 date-filter opacity-0 w-full absolute z-30 input-cursor-pointer"
										/>
										<div className="border p-0.5 border-border rounded">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="17"
												height="17"
												viewBox="0 0 18 19"
												fill="none">
												<path
													d="M11.1635 14.1409C10.725 14.1409 10.351 13.986 10.0413 13.6764C9.73173 13.3668 9.57692 12.9928 9.57692 12.5543C9.57692 12.1159 9.73173 11.7418 10.0413 11.4322C10.351 11.1226 10.725 10.9678 11.1635 10.9678C11.6019 10.9678 11.976 11.1226 12.2856 11.4322C12.5952 11.7418 12.75 12.1159 12.75 12.5543C12.75 12.9928 12.5952 13.3668 12.2856 13.6764C11.976 13.986 11.6019 14.1409 11.1635 14.1409ZM4.21153 16.3909C3.86634 16.3909 3.57812 16.2752 3.34688 16.044C3.11563 15.8127 3 15.5245 3 15.1793V5.60239C3 5.2572 3.11563 4.96898 3.34688 4.73773C3.57812 4.50648 3.86634 4.39086 4.21153 4.39086H5.53845V2.71777H6.34614V4.39086H11.7115V2.71777H12.4616V4.39086H13.7885C14.1337 4.39086 14.4219 4.50648 14.6531 4.73773C14.8844 4.96898 15 5.2572 15 5.60239V15.1793C15 15.5245 14.8844 15.8127 14.6531 16.044C14.4219 16.2752 14.1337 16.3909 13.7885 16.3909H4.21153ZM4.21153 15.6409H13.7885C13.9038 15.6409 14.0096 15.5928 14.1058 15.4966C14.2019 15.4005 14.25 15.2947 14.25 15.1793V8.60239H3.75V15.1793C3.75 15.2947 3.79808 15.4005 3.89423 15.4966C3.99039 15.5928 4.09616 15.6409 4.21153 15.6409ZM3.75 7.85239H14.25V5.60239C14.25 5.48701 14.2019 5.38124 14.1058 5.28508C14.0096 5.18893 13.9038 5.14086 13.7885 5.14086H4.21153C4.09616 5.14086 3.99039 5.18893 3.89423 5.28508C3.79808 5.38124 3.75 5.48701 3.75 5.60239V7.85239Z"
													fill="#123258"
												/>
											</svg>
										</div>
										<input
											type="text"
											disabled
											value={selectedDate}
											className={clsx(
												'border-none text-xs p-0 font-semibold rounded text-primary placeholder:text-primary',
												selectedDate ? 'w-16' : appLanguage === Language.EN ? 'w-8' : 'w-8'
											)}
										/>
									</div>

									<div className="flex flex-col gap-y-2 items-center">
										<h6 className="font-domine whitespace-nowrap text-center text-xs text-tertiary">
											{t(tKey('labels.assignedBy'))}:
										</h6>
										<p className="text-primary text-center text-xs font-semibold">
											{assignedBy.fname + ' ' + assignedBy.lname}
										</p>
									</div>

									<Menu as="div" className="relative md:hidden rounded-xl inline-block text-left">
										{({ open }) => (
											<>
												<Menu.Button className="flex w-full text-primary text-xs rounded focus:outline-none">
													<div className="flex flex-col gap-y-2 items-center">
														<p className="font-domine text-center text-xs text-tertiary">
															{t(tKey('labels.assignee'))}:
														</p>
														{selectedAssignee ? (
															<p className="text-xs text-primary font-semibold">
																{employees.find(employee => employee._id === selectedAssignee)
																	?.fname +
																	' ' +
																	employees.find(employee => employee._id === selectedAssignee)
																		?.lname}
															</p>
														) : (
															<p className="text-primary text-center text-xs font-semibold">
																{t(tKey('labels.noAssignee'))}
															</p>
														)}
													</div>
												</Menu.Button>
												{open && (
													<Transition
														as={Fragment}
														leave="transition ease-in duration-100"
														leaveFrom="opacity-100"
														leaveTo="opacity-0">
														<Menu.Items
															className={clsx(
																'absolute pt-1 text-xs z-10 mt-1 origin-top-left w-max divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -left-14'
															)}
															static>
															<div>
																{employees.map((employee, index) => (
																	<Menu.Item key={employee._id}>
																		<button
																			className={clsx(
																				'group text-primary font-semibold w-full text-xs whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left items-center rounded-md px-4 py-2'
																			)}
																			onClick={() => setSelectedAssignee(employee._id)}>
																			{employee.fname + ' ' + employee.lname}
																		</button>
																	</Menu.Item>
																))}
															</div>
														</Menu.Items>
													</Transition>
												)}
											</>
										)}
									</Menu>
								</div>
								<div className="flex flex-col gap-y-1">
									<input
										placeholder={t(tKey('placeholders.taskTitle'))}
										{...register('taskName')}
										className="border-none pl-0 outline-none focus:ring-0 placeholder:text-primary placeholder:text-lg text-lg font-bold font-domine text-primary"
									/>
									{errors?.taskName?.message && (
										<p className="text-xs text-red-500">{errors?.taskName?.message}</p>
									)}
								</div>
								<div className="flex flex-col gap-y-1">
									<textarea
										rows={5}
										{...register('description')}
										placeholder={t(tKey('placeholders.writeSomething'))}
										className="p-2 md:p-4 max-md:text-sm rounded focus:ring-0 border border-border focus:border-primary-light placeholder:text-tertiary text-primary"
									/>
									{errors?.description?.message && (
										<p className="text-xs text-red-500">{errors?.description?.message}</p>
									)}
								</div>
								<div className="flex flex-col gap-y-5">
									<div className="flex flex-col gap-y-5">
										<div className="flex items-center gap-x-5">
											<h2 className="text-primary font-domine text-sm md:text-lg font-bold">
												{t(tKey('titles.attachments'))}
											</h2>
											<div className="relative flex justify-between items-start">
												<input
													name="attachment"
													type="file"
													className="hidden w-full appearance-none"
													multiple={true}
													onChange={handleInputChange}
												/>

												<button
													type="button"
													className="text-xs text-primary hover:bg-[#06AB910F] font-semibold px-2 py-1 rounded border border-secondary"
													onClick={() => {
														const fileInput = document.querySelector(
															`[name=attachment]`
														) as HTMLInputElement
														fileInput.click()
													}}>
													{t(tKey('buttons.addFile'))}
												</button>
											</div>
										</div>
										<div className="p-3 flex max-md:flex-col max-md:gap-y-3 items-start gap-x-4 overflow-x-auto">
											{attachments.length > 0 ? (
												attachments.map((attachment, index) => (
													<div
														key={attachment.name}
														onClick={() =>
															'fileUrl' in attachment
																? Object.assign(document.createElement('a'), {
																		target: '_blank',
																		rel: 'noopener noreferrer',
																		href: attachment.fileUrl
																	}).click()
																: undefined
														}
														className="group cursor-pointer relative md:basis-1/5 flex max-md:flex-col">
														<div className="flex md:flex-col w-full items-center gap-x-2 md:gap-y-2">
															<div className="py-[3px] px-1.5 rounded border border-[#EEE] bg-background">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="64"
																	height="64"
																	viewBox="0 0 64 64"
																	className="max-md:h-[35px] max-md:w-[35px]"
																	fill="none">
																	<path
																		d="M23.9999 46.6667H39.9999C40.3777 46.6667 40.6944 46.5387 40.9499 46.2828C41.2055 46.0269 41.3333 45.7098 41.3333 45.3315C41.3333 44.9532 41.2055 44.6367 40.9499 44.3821C40.6944 44.1274 40.3777 44 39.9999 44H23.9999C23.6221 44 23.3055 44.128 23.0499 44.3839C22.7944 44.6397 22.6666 44.9568 22.6666 45.3351C22.6666 45.7134 22.7944 46.0299 23.0499 46.2846C23.3055 46.5393 23.6221 46.6667 23.9999 46.6667ZM23.9999 36H39.9999C40.3777 36 40.6944 35.872 40.9499 35.6161C41.2055 35.3603 41.3333 35.0432 41.3333 34.6649C41.3333 34.2866 41.2055 33.9701 40.9499 33.7154C40.6944 33.4607 40.3777 33.3333 39.9999 33.3333H23.9999C23.6221 33.3333 23.3055 33.4613 23.0499 33.7172C22.7944 33.9731 22.6666 34.2902 22.6666 34.6685C22.6666 35.0468 22.7944 35.3633 23.0499 35.6179C23.3055 35.8726 23.6221 36 23.9999 36ZM17.6409 56C16.4136 56 15.3888 55.5889 14.5666 54.7667C13.7444 53.9444 13.3333 52.9197 13.3333 51.6923V12.3077C13.3333 11.0803 13.7444 10.0556 14.5666 9.23333C15.3888 8.41111 16.4136 8 17.6409 8H36.8769C37.4664 8 38.0283 8.11625 38.5626 8.34873C39.0969 8.58118 39.5572 8.89058 39.9435 9.27693L49.3897 18.7231C49.776 19.1094 50.0854 19.5697 50.3179 20.104C50.5503 20.6383 50.6666 21.2002 50.6666 21.7897V51.6923C50.6666 52.9197 50.2555 53.9444 49.4333 54.7667C48.611 55.5889 47.5863 56 46.3589 56H17.6409ZM37.3333 19.2C37.3333 19.8044 37.5377 20.3111 37.9466 20.72C38.3555 21.1289 38.8621 21.3333 39.4666 21.3333H47.9999L37.3333 10.6667V19.2Z"
																		fill="#7F9AB2"
																	/>
																</svg>
															</div>
															<h5 className="text-primary text-xs font-semibold break-all">
																{attachment.name}
															</h5>
														</div>
														<div
															onClick={event => {
																event.stopPropagation()
																const newAttachments = [...attachments]
																newAttachments.splice(index, 1)
																setAttachments(newAttachments as File[])
															}}
															className="absolute hidden group-hover:block cursor-pointer drop-shadow-lg rounded-full p-0.5 bg-white top-0 max-md:left-10 md:right-0 md:-translate-x-3 -translate-y-2 z-20">
															<XMarkIcon className="max-md:h-2.5 max-md:w-2.5 h-3.5 w-3.5 text-primary" />
														</div>
													</div>
												))
											) : (
												<p className="whitespace-nowrap text-tertiary text-sm">
													{t(tKey('labels.noAttachments'))}
												</p>
											)}
										</div>
									</div>
									<div
										style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
										className="p-3 md:p-6 rounded-xl flex justify-between items-center">
										<div className="flex gap-x-3 items-center">
											<h2 className="text-primary font-domine text-lg font-bold">
												{t(tKey('titles.subTask'))}
											</h2>
											{fields.length > 0 && (
												<div className="px-2.5 rounded-full border border-border">
													<span className="text-primary font-domine text-xs md:text-lg font-bold">
														{fields.length}
													</span>
												</div>
											)}
										</div>
										<div
											style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
											onClick={() =>
												append({
													taskName: '',
													description: ''
												})
											}
											className="border cursor-pointer border-secondary rounded p-1">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												className="max-md:w-[19px] max-md:h-[19px]"
												fill="none">
												<path
													d="M11.5 12.5H6V11.5H11.5V6H12.5V11.5H18V12.5H12.5V18H11.5V12.5Z"
													fill="#123258"
												/>
											</svg>
										</div>
									</div>
									{fields.map((_field, index) => {
										return (
											<div
												key={index}
												className="p-3 md:p-4 bg-[#D3E3F133] rounded-xl flex flex-col gap-y-3">
												<Disclosure defaultOpen={true}>
													{({ open }) => (
														<>
															<Disclosure.Button className="flex w-full justify-between items-center focus:outline-none">
																<div className="flex w-full flex-col items-start gap-y-1">
																	<input
																		placeholder={t(tKey('placeholders.taskTitle'))}
																		{...register(`subTasks.${index}.taskName`)}
																		onKeyDown={event => event.stopPropagation()}
																		onClick={event => event.stopPropagation()}
																		className="border-none pl-0 w-full bg-transparent focus:ring-0 text-lg md:text-[20px] font-domine text-primary font-bold placeholder:text-primary"
																	/>
																	{errors?.['subTasks']?.[index]?.taskName?.message && (
																		<p className="text-xs text-red-500">
																			{errors?.['subTasks']?.[index]?.taskName?.message}
																		</p>
																	)}
																</div>
																<div className="flex items-center gap-x-2.5">
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		height="21"
																		viewBox="0 0 20 21"
																		onClick={() => remove(index)}
																		className="cursor-pointer"
																		fill="none">
																		<path
																			d="M6.08975 17.5832C5.67549 17.5832 5.32085 17.4356 5.02585 17.1406C4.73084 16.8456 4.58333 16.491 4.58333 16.0767V5.49984H4.375C4.19792 5.49984 4.04948 5.43992 3.92969 5.32007C3.8099 5.20024 3.75 5.05174 3.75 4.87457C3.75 4.69742 3.8099 4.54901 3.92969 4.42936C4.04948 4.3097 4.19792 4.24986 4.375 4.24986H7.49998C7.49998 4.04581 7.57183 3.87193 7.71552 3.72824C7.85922 3.58454 8.03309 3.5127 8.23715 3.5127H11.7628C11.9669 3.5127 12.1407 3.58454 12.2844 3.72824C12.4281 3.87193 12.5 4.04581 12.5 4.24986H15.625C15.802 4.24986 15.9505 4.30979 16.0703 4.42963C16.1901 4.54948 16.25 4.69798 16.25 4.87513C16.25 5.0523 16.1901 5.20071 16.0703 5.32036C15.9505 5.44001 15.802 5.49984 15.625 5.49984H15.4166V16.0767C15.4166 16.491 15.2691 16.8456 14.9741 17.1406C14.6791 17.4356 14.3245 17.5832 13.9102 17.5832H6.08975ZM14.1666 5.49984H5.83331V16.0767C5.83331 16.1515 5.85735 16.213 5.90544 16.2611C5.95352 16.3091 6.01496 16.3332 6.08975 16.3332H13.9102C13.985 16.3332 14.0464 16.3091 14.0945 16.2611C14.1426 16.213 14.1666 16.1515 14.1666 16.0767V5.49984ZM8.46181 14.6665C8.63897 14.6665 8.78737 14.6066 8.90702 14.4868C9.02669 14.367 9.08652 14.2186 9.08652 14.0415V7.79149C9.08652 7.61442 9.0266 7.46599 8.90675 7.3462C8.7869 7.2264 8.6384 7.16651 8.46125 7.16651C8.28408 7.16651 8.13567 7.2264 8.01602 7.3462C7.89637 7.46599 7.83654 7.61442 7.83654 7.79149V14.0415C7.83654 14.2186 7.89647 14.367 8.01631 14.4868C8.13615 14.6066 8.28465 14.6665 8.46181 14.6665ZM11.5387 14.6665C11.7159 14.6665 11.8643 14.6066 11.9839 14.4868C12.1036 14.367 12.1634 14.2186 12.1634 14.0415V7.79149C12.1634 7.61442 12.1035 7.46599 11.9836 7.3462C11.8638 7.2264 11.7153 7.16651 11.5381 7.16651C11.361 7.16651 11.2126 7.2264 11.0929 7.3462C10.9733 7.46599 10.9134 7.61442 10.9134 7.79149V14.0415C10.9134 14.2186 10.9734 14.367 11.0932 14.4868C11.2131 14.6066 11.3616 14.6665 11.5387 14.6665Z"
																			fill="#7F9AB2"
																		/>
																	</svg>
																	<ChevronDownIcon
																		className={clsx('h-5 w-5 text-primary', {
																			'rotate-180 transform': open
																		})}
																	/>
																</div>
															</Disclosure.Button>
															<Disclosure.Panel>
																<div className="flex flex-col gap-y-1">
																	<textarea
																		rows={5}
																		{...register(`subTasks.${index}.description`)}
																		placeholder={t(tKey('placeholders.writeSomething'))}
																		className="md:p-4 p-3 border w-full border-border focus:ring-0 max-md:text-sm focus:border-primary-light rounded placeholder:text-tertiary text-primary"
																	/>
																	{errors?.['subTasks']?.[index]?.description?.message && (
																		<p className="text-xs text-red-500">
																			{errors?.['subTasks']?.[index]?.description?.message}
																		</p>
																	)}
																</div>
															</Disclosure.Panel>
														</>
													)}
												</Disclosure>
											</div>
										)
									})}
								</div>
							</div>
						</div>

						<div
							style={{ boxShadow: '0px 4px 24px 0px rgba(0, 31, 63, 0.08)' }}
							className="md:basis-[35%] flex flex-col p-3 md:p-6 rounded-xl">
							<div className="flex flex-col justify-between gap-y-5">
								<div className="flex justify-between items-center">
									<h2 className="text-primary font-domine md:text-2xl font-bold">
										{t(tKey('titles.activity'))}
									</h2>
									<Menu
										as="div"
										className="relative max-md:hidden rounded-xl inline-block text-left">
										{({ open }) => (
											<>
												<Menu.Button className="flex w-full text-primary text-sm rounded focus:outline-none">
													<div className="flex gap-x-2.5 items-center">
														{t(tKey('labels.assignee'))}:{' '}
														{selectedAssignee ? (
															<p
																style={{ boxShadow: '0px 3.6px 14.4px 0px rgba(18, 50, 88, 0.08)' }}
																className="rounded-full p-1 border border-border text-xs text-primary font-semibold">
																<Tooltip
																	placement="bottom"
																	style="light"
																	className="whitespace-nowrap text-primary text-sm"
																	content={
																		employees.find(employee => employee._id === selectedAssignee)
																			?.fname +
																		' ' +
																		employees.find(employee => employee._id === selectedAssignee)
																			?.lname
																	}>
																	{employees
																		.find(employee => employee._id === selectedAssignee)
																		?.fname?.slice(0, 1)}
																	{employees
																		.find(employee => employee._id === selectedAssignee)
																		?.lname?.slice(0, 1)}
																</Tooltip>
															</p>
														) : (
															<p
																style={{ boxShadow: '0px 3.6px 14.4px 0px rgba(18, 50, 88, 0.08)' }}
																className="rounded-full p-1 border border-border text-xs text-primary font-semibold">
																<PlusIcon className="h-3 w-3" />
															</p>
														)}
													</div>
												</Menu.Button>
												{open && (
													<Transition
														as={Fragment}
														leave="transition ease-in duration-100"
														leaveFrom="opacity-100"
														leaveTo="opacity-0">
														<Menu.Items
															className={clsx(
																'absolute pt-1 text-sm z-10 mt-1 origin-top-left w-max divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -left-14'
															)}
															static>
															<div>
																{employees.map((employee, index) => (
																	<Menu.Item key={employee._id}>
																		<button
																			className={clsx(
																				'group text-primary font-semibold w-full text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left items-center rounded-md px-6 py-2'
																			)}
																			onClick={() => setSelectedAssignee(employee._id)}>
																			{employee.fname + ' ' + employee.lname}
																		</button>
																	</Menu.Item>
																))}
															</div>
														</Menu.Items>
													</Transition>
												)}
											</>
										)}
									</Menu>
								</div>
								<p className="text-xs max-md:hidden text-tertiary">
									{t(tKey('labels.assignedBy'))}:{' '}
									<span className="text-primary font-semibold">
										{assignedBy.fname + ' ' + assignedBy.lname}
									</span>
								</p>
							</div>
							<div className="flex flex-col mt-4 md:mt-8 gap-y-3.5">
								{commentValue && commentValue.length > 11 && (
									<div
										style={{ boxShadow: '0px 4px 24px 0px rgba(18, 50, 88, 0.06)' }}
										className="p-3 relative flex flex-col gap-y-3 rounded-lg border border-border">
										<div className="flex justify-between items-center">
											<div className="flex gap-x-3 items-center">
												<img src={ProfilePhotoIcon} className="h-8 w-8" />
												<p className="text-primary font-bold">
													{assignedBy.fname + ' ' + assignedBy.lname}
												</p>
											</div>

											<EllipsisMenu
												orientation="vertical"
												options={[
													{
														icon: TrashIcon,
														color: 'text-[#F84C6B]',
														action: () => setCommentValue('')
													}
												]}
											/>
										</div>
										<p
											className="text-primary break-all text-sm comment"
											dangerouslySetInnerHTML={{ __html: commentValue }}
										/>
									</div>
								)}
								<ReactQuill
									className="editor"
									theme="snow"
									placeholder={t(tKey('placeholders.writeComment'))}
									value={commentValue}
									onChange={setCommentValue}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className={clsx(
						'fixed bg-white z-30 w-full justify-end flex gap-x-3.5 items-center bottom-0 border-t border-[#1232581A] px-6 py-3',
						{ 'md:-translate-x-[256px]': isSidebarVisible }
					)}>
					<button
						type="button"
						onClick={() => navigate(-1)}
						className="border max-md:hidden border-primary max-md:text-sm rounded px-4 py-2 md:px-8 md:py-3 text-primary font-semibold">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button className="font-semibold border border-primary max-md:text-sm py-2 px-3 md:py-3 md:px-8">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{taskId ? t(tKey('buttons.update')) : t(tKey('buttons.create'))}</span>
						)}
					</Button>
				</div>
			</form>
		</AppLayout>
	)
}
