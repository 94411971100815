import axios from 'services/axios'

const getServiceByCompany = (companyId: string): Promise<Service[]> => {
	return axios.get(`/company/service/${companyId}`).then(response => response.data)
}

const getServiceById = (serviceId: string): Promise<Service> => {
	return axios.get(`/service/${serviceId}`).then(response => response.data)
}

const createService = (data: ServiceForm) => {
	return axios.post('/service', data).then(response => response.data)
}

const createServiceSchedule = (data: ScheduleForm) => {
	return axios.post('/scedule', data).then(response => response.data)
}

const deleteServiceSchedule = (scheduleId: string) => {
	return axios.delete(`/scedule/${scheduleId}`).then(response => response.data)
}

const updateService = (serviceId: string, data: ServiceForm) => {
	return axios.put(`/service/${serviceId}`, data).then(response => response.data)
}

const deleteService = (serviceId: string) => {
	return axios.delete(`/service/${serviceId}`).then(response => response.data)
}

const getCompanyServiceByCategory = (categoryId: string): Promise<Service[]> => {
	return axios.get(`/service?id_category=${categoryId}`).then(response => response.data)
}

const getServiceSchedule = (id: string): Promise<Schedule[]> => {
	return axios.get(`/service/scedule/${id}`).then(response => response.data)
}

const getRevenueStatsByWeek = (serviceId?: string): Promise<WeeklyServiceRevenue[]> => {
	return axios
		.get(`/services/stats/weekly${serviceId ? `?serviceId=${serviceId}` : ''}`)
		.then(response => response.data)
}

const getRevenueStatsByYear = (
	year: number,
	serviceId?: string
): Promise<MonthlyServiceRevenue[]> => {
	return axios
		.get(`/services/stats/yearly?year=${year}${serviceId ? `&serviceId=${serviceId}` : ''}`)
		.then(response => response.data)
}

const medicalService = {
	getServiceByCompany,
	getServiceSchedule,
	getRevenueStatsByWeek,
	getRevenueStatsByYear,
	createServiceSchedule,
	deleteServiceSchedule,
	getServiceById,
	updateService,
	createService,
	deleteService,
	getCompanyServiceByCategory
}

export default medicalService
