import axios from 'services/axios'

const addGalleryImages = (companyId: string, data: FormData) => {
	return axios
		.post(`/gallery/company/${companyId}`, data, {
			headers: { 'Content-Type': 'multipart/form-data' }
		})
		.then(response => response.data)
}

const deleteGalleryImage = (companyId: string, imageUrl: string) => {
	return axios.post(`/delete/company/${companyId}`, { imageUrl }).then(response => response.data)
}

const galleryService = {
	addGalleryImages,
	deleteGalleryImage
}

export default galleryService
