import axios from 'services/axios'

const getLicenses = (): Promise<License[]> => {
	return axios.get('/licenses').then(response => response.data)
}

const verifyLicense = (sessionId: string) => {
	return axios.get(`/license/verify-checkout-session?session_id=${sessionId}`)
}

const createLicense = (data: { numLicenses: number; months: number; companyId: string }) => {
	return axios.post('/license/purchase', data).then(response => response.data)
}

const licenseService = {
	getLicenses,
	verifyLicense,
	createLicense
}

export default licenseService
