import axios from 'services/axios'

const getCompanyById = (companyId: string): Promise<Company> => {
	return axios.get(`/company/${companyId}`).then(response => response.data)
}

const getCompanyLogo = (id: string): Promise<{ name: string; url: string }[]> => {
	return axios.get(`/logo/company?company_name=${id}`).then(response => response.data)
}

const getCompanySlider = (id: string): Promise<{ name: string; url: string }[]> => {
	return axios.get(`/slider/company?company_name=${id}`).then(response => response.data)
}

const getCompanyTypes = (): Promise<CompanyType[]> => {
	return axios.get('/all/types').then(response => response.data)
}

const getCompanySchedule = (companyId: string): Promise<Schedule[]> => {
	return axios.get(`/company/scedule/${companyId}`).then(response => response.data)
}

const getSubCompanies = (parentId: string): Promise<CompanyWithStats[]> => {
	return axios.get(`/subcompanies/${parentId}`).then(response => response.data)
}

const createCompany = (data: FormData) => {
	return axios
		.post('/company', data, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const createCompanySchedule = (data: ScheduleForm) => {
	return axios.post(`/scedule`, data).then(response => response.data)
}

const createSubCompanySchedule = (companyId: string, data: ScheduleForm) => {
	return axios.post(`/schedule/subcompany/${companyId}`, data).then(response => response.data)
}

const updateCompanySchedule = (scheduleId: string, data: ScheduleForm) => {
	return axios.put(`/scedule/${scheduleId}`, data).then(response => response.data)
}

const updateSubCompanySchedule = (scheduleId: string, data: ScheduleForm) => {
	return axios.put(`/schedule/subcompany/${scheduleId}`, data).then(response => response.data)
}

const updateCompanyStatus = (companyId: string, data: { status: string }) => {
	return axios.post(`/updateStatus/${companyId}`, data).then(response => response.data)
}

const getCompanyDepartments = (): Promise<Department[]> => {
	return axios.get('/departments').then(response => response.data)
}

const updateClient = (clientId: string, action: 'block' | 'unblock') => {
	return axios
		.post(`/company/update-client/${clientId}`, { action })
		.then(response => response.data)
}

const stripeOnboard = (companyId: string) => {
	return axios.post(`/company/stripe-onboard/${companyId}`).then(response => response.data)
}

const addCompanyAccount = (stripeId: string, companyId: string) => {
	return axios
		.post(`/company/stripe-update/${companyId}`, { stripe_account: stripeId })
		.then(response => response.data)
}

const updateCompanyDetails = (id: string, data: FormData | Object, hasSlider?: boolean) => {
	return axios
		.put(`/company/${id}?slider=${hasSlider}`, data, {
			headers: {
				'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json'
			}
		})
		.then(response => response.data)
}

const updateSubCompanyDetails = (id: string, data: FormData | Object, hasSlider?: boolean) => {
	return axios
		.put(`/sub-company/${id}?slider=${hasSlider}`, data, {
			headers: {
				'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json'
			}
		})
		.then(response => response.data)
}

const companyService = {
	getCompanyById,
	getCompanyTypes,
	getCompanySchedule,
	getCompanyLogo,
	createCompany,
	stripeOnboard,
	getCompanySlider,
	updateClient,
	getSubCompanies,
	getCompanyDepartments,
	createCompanySchedule,
	updateCompanySchedule,
	createSubCompanySchedule,
	updateSubCompanySchedule,
	addCompanyAccount,
	updateCompanyStatus,
	updateCompanyDetails,
	updateSubCompanyDetails
}

export default companyService
