import React from 'react'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import Routing from 'routing'
import store from 'store'

import { SocketProvider } from 'sockets/socket-provider'

import 'react-toastify/dist/ReactToastify.css'
import 'styles/main.css'
import './i18n'
import '/node_modules/flag-icons/css/flag-icons.min.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const persistor = persistStore(store)

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
	navigator.userAgent
)
const backend = isMobile ? TouchBackend : TouchBackend
const delay = 300

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<SocketProvider>
					<ToastContainer limit={3} newestOnTop={true} pauseOnFocusLoss={false} />
					<DndProvider backend={backend} options={{ enableMouseEvents: true, delay: delay }}>
						<Routing />
					</DndProvider>
				</SocketProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>
)
