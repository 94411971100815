import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer'

import { DateTime } from 'luxon'

Font.register({
	family: 'Open Sans',
	fonts: [
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
			fontWeight: 600
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
			fontWeight: 700
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf',
			fontWeight: 800
		}
	]
})

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Open Sans',
		fontSize: 12
	},
	row: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 40
	}
})

interface AppointmentDocumentProps {
	appointment: Appointment
	qrCodeURL: string
}

export const ApppointmentDocument = ({ appointment, qrCodeURL }: AppointmentDocumentProps) => {
	return (
		<Document>
			<Page wrap={false} size="A4" style={styles.page}>
				<View style={{ paddingTop: 20, paddingHorizontal: 30 }}>
					<View
						style={{
							paddingVertical: '10px',
							display: 'flex',
							borderRadius: '12px',
							backgroundColor: '#5964D3',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
						<Image src={window.location.origin + '/images/logo.png'} style={{ height: '60px' }} />
						<Text
							style={{ color: 'white', fontWeight: 'bold', fontSize: '24px', textAlign: 'center' }}>
							Dein Termin ist nun gebucht!
						</Text>
					</View>
					<View
						style={{
							paddingVertical: '20px',
							display: 'flex',
							gap: '10px',
							alignItems: 'flex-start',
							flexDirection: 'column'
						}}>
						<Text
							style={{ marginBottom: 10, fontWeight: 'bold', fontSize: '12px', color: '#070027' }}>
							Buchungsdetails:
						</Text>
						<View
							style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 20 }}>
							<View
								style={{
									display: 'flex',
									flexDirection: 'column',
									flexBasis: '30%',
									gap: 10
								}}>
								<Text style={{ fontWeight: 'bold', fontSize: '10px' }}>Name:</Text>
								<Text style={{ fontWeight: 'bold', fontSize: '10px' }}>Zeit:</Text>
								<Text style={{ fontWeight: 'bold', fontSize: '10px' }}>Leistung:</Text>
								<Text style={{ fontWeight: 'bold', fontSize: '10px' }}>Preis:</Text>
								<Text style={{ fontWeight: 'bold', fontSize: '10px' }}>Firma:</Text>
								<Text style={{ fontWeight: 'bold', fontSize: '10px' }}>Ort:</Text>
							</View>
							<View
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
									gap: 10,
									fontSize: '10px',
									flexBasis: '50%'
								}}>
								<Text>{appointment.id_client.fname + ' ' + appointment.id_client.lname}</Text>
								<Text>{DateTime.fromMillis(appointment.from).toFormat('dd.LL.yyyy, HH:mm')}</Text>
								<Text>{appointment.service_name}</Text>
								<Text>
									{appointment.is_discounted
										? Math.max(
												Number(appointment.service.discountedPrice) -
													(appointment.used_coupon ? appointment.used_coupon.discount_amount : 0),
												0
											).toFixed(2) + ' CHF'
										: Math.max(
												Number(appointment.service.price) -
													(appointment.used_coupon ? appointment.used_coupon.discount_amount : 0),
												0
											).toFixed(2) + ' CHF'}
								</Text>
								<Text>Styling Corner</Text>
								<Text>
									{appointment.id_company.address +
										' ' +
										appointment.id_company.address_no +
										' , ' +
										appointment.id_company.zip +
										' ' +
										appointment.id_company.city}
								</Text>
							</View>
						</View>

						<View
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								width: '100%',
								gap: 20,
								marginVertical: 20
							}}>
							<View
								style={{
									display: 'flex',
									width: '100%',
									alignSelf: 'flex-start',
									flexDirection: 'column',
									alignItems: 'flex-start',
									gap: 10
								}}>
								<Image
									src={qrCodeURL}
									style={{ width: '80px', height: '80px', alignSelf: 'center' }}
								/>
								<Text style={{ fontSize: '10px' }}>
									Scannen Sie diesen QR-Code beim Termin für ein reibungsloses Check-in-Erlebnis.
								</Text>
								<Link
									src={appointment.id_company.google_map}
									style={{
										backgroundColor: '#5964D3',
										padding: 10,
										color: 'white',
										alignSelf: 'flex-start',
										textDecoration: 'none',
										borderRadius: '4px',
										fontSize: '10px',
										fontWeight: 'bold'
									}}>
									Routenplaner
								</Link>
							</View>

							<View
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignSelf: 'flex-start',
									alignItems: 'flex-start',
									gap: 5
								}}>
								<Text style={{ fontSize: '10px' }}>Ist etwas dazwischengekommen?</Text>
								<Text style={{ fontSize: '10px' }}>
									Sie können Ihren Termin bis zu 3 Stunden vor Terminbeginn kostenlos stornieren.
								</Text>
								<Link
									src={`https://stylingcorner.ch/cancel?appointmentId=${appointment._id}`}
									style={{
										backgroundColor: '#5964D3',
										padding: 10,
										marginTop: 5,
										color: 'white',
										fontSize: '10px',
										textDecoration: 'none',
										borderRadius: '4px',
										fontWeight: 'bold'
									}}>
									Termin stornieren
								</Link>
							</View>

							<View
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									gap: 5
								}}>
								<Text style={{ fontSize: '10px' }}>Viele Grüsse,</Text>
								<Text style={{ fontSize: '10px' }}>dein Styling Corner Team</Text>
								<Text style={{ fontSize: '10px' }}>
									Für weitere Unterstützung erreichen Sie uns über{' '}
									<Text style={{ color: '#5964D3' }}>support@resolut-technologies.ch</Text> oder
									telefonisch unter <Text style={{ color: '#5964D3' }}>0848 884 884</Text>.
								</Text>
							</View>
						</View>
					</View>
				</View>
				<View
					style={{
						display: 'flex',
						width: '100%',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 5,
						paddingVertical: 15,
						backgroundColor: '#C6C7F8'
					}}>
					<Text style={{ fontSize: '10px' }}>
						© {new Date().getFullYear()} Resolut Technologies GmbH
					</Text>
					<Text style={{ fontSize: '10px' }}>Pilatusstrasse 28, CH-6052 Hergiswil NW</Text>
					<View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 4 }}>
						<Link src="https://stylingcorner.ch/" style={{ color: '#5964D3', fontSize: '10px' }}>
							Website
						</Link>
						<Text>|</Text>
						<Link
							src="https://www.stylingcorner.pro/datenschutzzentrum"
							style={{ color: '#5964D3', fontSize: '10px' }}>
							Privacy Policy
						</Link>
						<Text>|</Text>
						<Link
							src="https://resoluttechnologies-assist.freshdesk.com/support/tickets/new"
							style={{ color: '#5964D3', fontSize: '10px' }}>
							Help
						</Link>
					</View>
				</View>
			</Page>
		</Document>
	)
}
