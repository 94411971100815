import axios from 'services/axios'

const createTicket = (data: FormData) => {
	return axios
		.post('/support', data, { headers: { 'Content-Type': `multipart/form-data` } })
		.then(response => response.data)
}

const ticketService = {
	createTicket
}

export default ticketService
