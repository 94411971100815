import { XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { CreateClient } from 'pages/clients/create'
import { getTKey } from 'utils/language'

interface CreateClientFormProps {
	setCreatedClient: (id: string) => void
	onClose: () => void
	onCreation: () => void
}

export const CreateClientForm = ({
	setCreatedClient,
	onClose,
	onCreation
}: CreateClientFormProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('calendar.createEvent')
	return (
		<div className="flex overflow-auto md:fixed md:inset-y-0 md:right-0 md:z-[999] bg-white md:min-h-screen md:w-[360px] flex-col gap-y-3">
			<div className="flex max-md:hidden h-11 justify-between items-center py-1.5 px-4">
				<h1 className="font-domine font-bold leading-6 text-[#1C1C1C]">
					{t(tKey('headings.addClient'))}
				</h1>
				<XMarkIcon onClick={onClose} className="h-5 w-5 cursor-pointer text-[#1C1C1C]" />
			</div>
			<CreateClient
				isCalendarForm
				setCreatedClient={(id: string) => setCreatedClient(id)}
				className="!p-5 !pt-3"
				onCreation={onCreation}
				onCancel={onClose}
			/>
		</div>
	)
}
