import { ProgressBar as Progress } from 'react-step-progress-bar'
import { ProgressBarProps } from 'react-toastify/dist/components'

import clsx from 'clsx'

import { CheckedCircleIcon, CircleIcon, CurrentCircleIcon } from 'assets/icons'
import { Step } from 'components/progress-bar/step'
import useIsMobile from 'hooks/useIsMobile'

interface ProgressBarCustomProps extends Partial<ProgressBarProps> {
	totalSteps: number
	currentStep: number
	steps: { label: string; onAction: () => void }[]
}

export const ProgressBar = ({ totalSteps, currentStep, steps }: ProgressBarCustomProps) => {
	const isMobile = useIsMobile()
	return (
		<Progress
			filledBackground="#123258"
			height={2}
			width={isMobile ? 250 : 630}
			percent={(currentStep / (totalSteps - 1)) * 100}>
			{Array.from({ length: totalSteps }, (_, index) => (
				<Step transition="scale" key={index}>
					{({ accomplished }: any) => (
						<div
							onClick={() => steps[index].onAction()}
							className="relative cursor-pointer flex flex-col items-center justify-center">
							{index === currentStep ? (
								<img src={CurrentCircleIcon} alt="current" className="max-lg:w-8 max-lg:h-8" />
							) : accomplished ? (
								<img src={CheckedCircleIcon} alt="check" className="max-lg:w-8 max-lg:h-8" />
							) : (
								<img src={CircleIcon} alt="normal" className="max-lg:w-8 max-lg:h-8" />
							)}
							<span
								className={clsx(
									'absolute text-center top-10 max-md:text-xs whitespace-nowrap',
									index === currentStep ? 'text-primary' : 'text-[#7F9AB2]'
								)}>
								{steps[index].label}
							</span>
						</div>
					)}
				</Step>
			))}
		</Progress>
	)
}
