import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { ProfilePhotoIcon } from 'assets/icons'
import clsx from 'clsx'
import { Button } from 'components/app/button'
import { ProfileImageUpload } from 'components/app/image-upload'
import { AppLayout } from 'components/app/layout'
import { Input } from 'components/inputs/input'
import { useAppDispatch, useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { useState } from 'react'
import { toast } from 'react-toastify'
import userService from 'services/user-service'
import { updateUserProfile } from 'slices/auth'
import { getTKey } from 'utils/language'

export const ChangePassword = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const dispatch = useAppDispatch()

	const auth = useAppSelector(state => state.auth)
	const [image, setImage] = useState<File>()

	const tKey = getTKey('changePassword')

	const schema = yup.object({
		oldPassword: yup.string().required(t(tKey('errors.oldPassword'))),
		newPassword: yup
			.string()
			.required(t(tKey('errors.newPassword')))
			.min(8, t(tKey('errors.minPassword'))),
		confirmPassword: yup
			.string()
			.required(t(tKey('errors.confirmPassword')))
			.oneOf([yup.ref('newPassword'), ''], t(tKey('errors.matchPassword')))
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<any>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	const handleImageUpload = () => {
		const formData = new FormData()
		if (image) {
			formData.append('file', image)
		}
		userService
			.updateUserProfile(formData)
			.then(res => {
				dispatch(updateUserProfile(res.data.profile_url))
				setImage(undefined)
				toast.success(t(tKey('toast.updateProfileSuccess')))
			})
			.catch(error => {
				if (error?.response?.data?.message) {
					return toast.error(error.response.data.message)
				}
				toast.error(t(tKey('toast.updateProfileError')))
			})
	}

	const onSubmit = (data: any) => {
		userService
			.updateUserPassword(data.oldPassword, data.newPassword)
			.then(() => {
				toast.success(t(tKey('toast.passwordSuccess')))
				reset()
			})
			.catch(err => toast.error(t(tKey('toast.invalidPassword'))))
	}

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('privacySettings'))}>
			<div className="p-5 md:p-12 divide-y-2 flex flex-col gap-y-5">
				<div className="flex flex-col gap-y-10">
					<h1 className="text-lg max-md:text-center text-primary font-bold">
						{t(tKey('headings.profilePicture'))}
					</h1>
					<ProfileImageUpload
						image={auth?.profile_url === 'crm' ? ProfilePhotoIcon : auth?.profile_url}
						onUpload={(file: File) => setImage(file)}
						name="user-profile"
					/>
					<button
						disabled={!image}
						onClick={handleImageUpload}
						className={clsx(
							'md:w-fit mt-3 py-3 px-8 text-white rounded md:ml-auto',
							image ? 'bg-primary-light' : 'bg-gray-400'
						)}>
						{t(tKey('labels.save'))}
					</button>
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-10 py-4">
					<h3 className="text-lg max-md:text-center text-primary font-bold">{t(tKey('title'))}</h3>
					<div className="flex flex-col gap-y-5 md:gap-y-8">
						<Input
							register={register}
							errors={errors}
							type="password"
							name="oldPassword"
							labelText={t(tKey('labels.oldPassword'))}
						/>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-y-5 gap-x-5">
							<Input
								register={register}
								errors={errors}
								type="password"
								name="newPassword"
								labelText={t(tKey('labels.newPassword'))}
							/>
							<Input
								register={register}
								errors={errors}
								type="password"
								name="confirmPassword"
								labelText={t(tKey('labels.reEnterPassword'))}
							/>
						</div>
					</div>
					<Button className="md:w-fit md:ml-auto">{t(tKey('labels.save'))}</Button>
				</form>
			</div>
		</AppLayout>
	)
}
