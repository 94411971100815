import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'

import clsx from 'clsx'

interface PaginationProps {
	items?: Array<any>
	itemsPerPage?: number
	renderComponent: (item: any) => JSX.Element
	totalSelectedPages?: number
}

const ShowItemPerPageOptions = [5, 10, 30, 50, 70, 100]

const DEFAULT_ITEMS_PER_PAGE = 10
const DEFAULT_PAGE_INDEX = 0

export const Pagination = ({
	items,
	renderComponent,
	totalSelectedPages = 100
}: PaginationProps) => {
	const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX)
	const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)

	const sliceStart = pageIndex * itemsPerPage
	const sliceEnd = sliceStart + itemsPerPage

	const [currItems, setCurrItems] = useState(items?.slice(sliceStart, sliceEnd))

	const numberOfBottomPages = Math.ceil((items ?? []).length / itemsPerPage)

	useEffect(() => {
		const updatedCurrItems = items?.slice(sliceStart, sliceEnd)

		if (updatedCurrItems?.length === 0 && numberOfBottomPages !== 0) {
			setPageIndex(numberOfBottomPages - 1)
		}
	}, [items, numberOfBottomPages])

	useEffect(() => {
		// Length changes if a filter is applied,
		// So we have to paginate the results again
		setCurrItems(items?.slice(sliceStart, sliceEnd))
	}, [items, items?.length, sliceStart, sliceEnd, pageIndex])

	useEffect(() => {
		setCurrItems(items?.slice(sliceStart, sliceEnd))
	}, [pageIndex])

	const totalPages = Math.ceil((items ?? []).length / itemsPerPage)

	let pageArray = [0, 1, 2]
		.map(v => pageIndex + 1 + v)
		.filter(page => page > 0 && page <= totalPages)

	if (pageIndex > totalPages - 4) {
		pageArray = [-1, 0, 1, 2]
			.map(v => pageIndex + 1 + v)
			.filter(page => page > 0 && page <= totalPages)
	}
	if (pageIndex > totalPages - 3) {
		pageArray = [-2, -1, 0, 1, 2]
			.map(v => pageIndex + 1 + v)
			.filter(page => page > 0 && page <= totalPages)
	}
	if (pageIndex > totalPages - 2) {
		pageArray = [-3, -2, -1, 0, 1, 2, 3]
			.map(v => pageIndex + 1 + v)
			.filter(page => page > 0 && page <= totalPages)
	}

	const goToPage = (index: number) => {
		if (index >= 0 && index <= totalPages - 1) {
			setPageIndex(index)
		}
	}

	const onNext = () => {
		// subtracting one because our page index starts from 0
		if (pageIndex < totalPages - 1) {
			setPageIndex(index => index + 1)
		}
	}

	const onPrevious = () => {
		if (pageIndex - 1 >= 0) {
			setPageIndex(index => index - 1)
		}
	}
	const onFirst = () => {
		setPageIndex(0)
	}

	return (
		<>
			{renderComponent(currItems)}
			<tfoot className="table-footer-group max-lg:flex max-lg:justify-center ">
				<tr>
					<td colSpan={200}>
						<div className="flex items-center py-5">
							<div className="flex sm:flex-1 sm:items-center sm:justify-between ">
								<div className="w-full sm:w-auto ml-auto text-right mt-2 sm:mt-0">
									<nav className="isolate inline-flex gap-x-2 rounded-md" aria-label="Pagination">
										<button
											type="button"
											className={clsx(
												'relative inline-flex items-center rounded p-1 text-sm font-medium text-[#FFFFFF] bg-[#1232584D] focus:z-20',
												pageIndex == 0 && 'disabled text-[#C4CDD5] opacity-30'
											)}
											onClick={onPrevious}>
											<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
											<span className="sr-only">Previous</span>
										</button>

										{pageArray.map(page => (
											<button
												type="button"
												key={page}
												className={clsx(
													'relative bg-white inline-flex w-8 items-center justify-center rounded border px-1 py-[5px] text-primary text-sm font-bold',
													page === pageIndex + 1
														? 'className="relative z-10 inline-flex items-center border-primary focus:z-20'
														: 'border-[#DFE3E8]'
												)}
												onClick={() => goToPage(page - 1)}>
												{page}
											</button>
										))}
										{!pageArray.includes(totalPages) && (
											<>
												{pageIndex < totalPages - 4 ? <div>...</div> : ''}
												<button
													type="button"
													key={'last'}
													className={clsx(
														'relative inline-flex w-8 items-center justify-center rounded border px-1 py-[5px] text-primary text-sm font-bold',
														totalPages - 1 === pageIndex
															? 'className="relative z-10 inline-flex items-center border-primary focus:z-20'
															: 'border-[#DFE3E8]'
													)}
													onClick={() => goToPage(totalPages - 1)}>
													{totalPages}
												</button>
											</>
										)}

										<button
											type="button"
											className={clsx(
												'relative inline-flex items-center rounded p-1 text-sm font-medium text-[#FFFFFF] bg-[#1232584D] focus:z-20',
												totalPages == pageIndex + 1 && 'disabled text-[#C4CDD5] opacity-30'
											)}
											onClick={onNext}>
											<span className="sr-only">Next</span>
											<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									</nav>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tfoot>
		</>
	)
}
