const firebaseCredentials = {
  apiKey: "AIzaSyDK0uLiDj2u_d-90LqwpJZGezMh_PXXw2E",
  authDomain: "styling-2f257.firebaseapp.com",
  projectId: "styling-2f257",
  storageBucket: "styling-2f257.appspot.com",
  messagingSenderId: "301106816192",
  appId: "1:301106816192:web:47974e21d738b51a363925"
};

export default firebaseCredentials
