import axios from 'axios'
import { apiHost } from 'utils/host'
import { indexedStorageDB } from 'utils/local-forage'

const userVerification = (username: string, otp: string) => {
	return axios.post(apiHost + '/auth/verify', { email: username, otp }).then(res => res.data)
}

const forgotPassword = (email: string) => {
	return axios.post(apiHost + '/auth/sendpassword', { email }).then(res => res.data)
}

const resetPassword = (email: string, newPassword: string, otp: string) => {
	return axios
		.post(apiHost + '/auth/verifypassword', { email, new_password: newPassword, otp })
		.then(res => res.data)
}

const login = (username: string, password: string) => {
	return axios
		.post(apiHost + '/auth/signin', {
			email: username,
			password
		})
		.then(response => {
			return response.data
		})
}

const logout = () => {
	indexedStorageDB.clear()
	window.location.reload()
}

const authService = {
	login,
	forgotPassword,
	resetPassword,
	userVerification,
	logout
}

export default authService
