import { default as i18n, default as i18next } from 'i18next'

import { Language } from 'constants/constants'

export const getAppLang = () => {
	const lang = window.localStorage.getItem('i18nLng') || i18n.language
	return (lang.includes(Language.DE) ? Language.DE : Language.EN) as AppLang
}

export const getTKey = (namespace: string) => {
	return (key: string): string => `${namespace || ''}.${key}`
}

export const t = (
	key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
	options?: { [key: string]: any }
) => {
	return (i18next.t(key as string, options ?? {}) as string) || ''
}

export const getLocalizedName = (obj: any) => {
	if (!obj) return ''
	const appLang = getAppLang()
	const key = appLang === Language.EN ? 'nameEn' : 'nameDe'
	return obj[key] ?? '-'
}
