import axios from 'services/axios'

const getAllTasks = (): Promise<Task[]> => {
	return axios.get('/task-assignments').then(response => response.data)
}

const getTaskById = (taskId: string): Promise<Task> => {
	return axios.get(`/task-assignments/${taskId}`).then(response => response.data)
}

const createTask = (data: FormData) => {
	return axios
		.post('/task-assignment', data, { headers: { 'Content-Type': `multipart/form-data` } })
		.then(response => response.data)
}

const updateTask = (taskId: string, data: FormData) => {
	return axios
		.put(`/task-assignments/${taskId}`, data, {
			headers: { 'Content-Type': `multipart/form-data` }
		})
		.then(response => response.data)
}

const deleteTask = (taskId: string) => {
	return axios.delete(`/task-assignments/${taskId}`).then(response => response.data)
}

const taskService = {
	getAllTasks,
	createTask,
	updateTask,
	getTaskById,
	deleteTask
}

export default taskService
