import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Spinner } from 'components/animations/spinner'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import ReactCalendar, { CalendarEvent } from 'components/calendar'
import { AppointmentStatus } from 'constants/constants'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { DateTime } from 'luxon'
import { CreateClient } from 'pages/clients/create'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import appointmentService from 'services/appointment-service'
import categoryService from 'services/category-service'
import employeeService from 'services/employee-service'
import eventService from 'services/event-service'
import taskService from 'services/task-service'
import { socket } from 'sockets/socket-context'
import { getTKey } from 'utils/language'
import { CreateOrUpdateAppointmentForm } from './appointment-form'
import { CreateClientForm } from './client-form'
import { CreateOrUpdateEventForm } from './event-form'

export const Calendar = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const tKey = getTKey('calendar')
	const auth = useAppSelector(state => state.auth)
	const [events, setEvents] = useState<CalendarEvent[]>([])
	const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>()
	const [isLoading, setIsLoading] = useState(false)
	const [showPopup, setShowPopup] = useState<'Event' | 'Appointment' | 'Client'>()
	const [selectedEvent, setSelectedEvent] = useState<string>()
	const [createdClient, setCreatedClient] = useState<string>()
	const [categories, setCategories] = useState<Category[]>([])
	const [employees, setEmployees] = useState<Employee[]>([])
	const [selectedEmployee, setSelectedEmployee] = useState<string>('')
	const [forceRefresh, setForceRefresh] = useState(false)
	const [view, setView] = useState<'day' | 'week' | 'month'>('week')
	const [currentDate, setCurrentDate] = useState(DateTime.now())

	const taskColors = {
		client: '#b22222',
		'custom task': '#e69138',
		appointment: '#607966'
	} as { [key: string]: string }

	const addCustomEvents = (customEvents: CustomEvent[]): CalendarEvent[] => {
		const repeatedEvents: CalendarEvent[] = []

		customEvents.forEach(event => {
			const startDate = DateTime.fromMillis(event.from)
			const endDate = DateTime.fromMillis(event.to)

			if (startDate.startOf('day') < endDate.startOf('day')) {
				let currentDate = startDate
				switch (event.duration) {
					case 'daily':
						while (currentDate <= endDate) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ days: 1 })
						}
						break
					case 'weekly':
						while (currentDate <= endDate) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ weeks: 1 })
						}
						break
					case 'monthly':
						while (currentDate <= endDate) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ months: 1 })
						}
						break
					case 'yearly':
						while (currentDate <= endDate) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ years: 1 })
						}
						break
					default:
						while (currentDate <= endDate) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ days: 1 })
						}
				}
			} else {
				let currentDate = startDate
				switch (event.duration) {
					case 'daily':
						while (currentDate <= DateTime.now().plus({ months: 2 })) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ days: 1 })
						}
						break
					case 'weekly':
						while (currentDate <= DateTime.now().plus({ months: 5 })) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ weeks: 1 })
						}
						break
					case 'monthly':
						while (currentDate <= DateTime.now().plus({ years: 1 })) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ months: 1 })
						}
						break
					case 'yearly':
						while (currentDate <= DateTime.now().plus({ years: 5 })) {
							repeatedEvents.push(
								createEvent(
									event,
									currentDate,
									currentDate.set({
										hour: endDate.hour,
										minute: endDate.minute,
										second: endDate.second
									})
								)
							)
							currentDate = currentDate.plus({ years: 1 })
						}
						break
					default:
						repeatedEvents.push(createEvent(event, currentDate, endDate))
				}
			}
		})

		return repeatedEvents
	}

	const createEvent = (
		event: CustomEvent,
		startDate: DateTime,
		endDate: DateTime
	): CalendarEvent => {
		return {
			...event,
			id: event._id,
			name: event.title,
			description: event.description,
			startDateTime: startDate.toISO() as string,
			endDateTime: startDate
				.plus({ milliseconds: endDate.diff(startDate).milliseconds })
				.toISO() as string,
			allDay: event.allDay,
			type: 'event',
			color: event.colorCode
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true)
				const categories = await categoryService.getCompanyCategories()
				const appointments = await appointmentService.getCompanyAppointments(auth.companyId)
				const tasks = await taskService.getAllTasks()
				const events = await eventService.getAllEvents()
				setCategories(categories)
				const customEvents = events
					.filter(event =>
						selectedEmployee
							? event.addedBy?._id === selectedEmployee ||
								event.guests.some(guest => guest._id === selectedEmployee)
							: true
					)
					.map((event: CustomEvent) => ({
						...event,
						type: 'event',
						startDateTime: DateTime.fromMillis(event.from).toISO(),
						endDateTime: DateTime.fromMillis(event.to).toISO()
					}))

				const appointmentEvents = appointments
					.filter((appointment: Appointment) =>
						selectedEmployee
							? appointment.id_employee?._id === selectedEmployee &&
								appointment.status !== AppointmentStatus.CANCELLED
							: appointment.status !== AppointmentStatus.CANCELLED
					)
					.map((appointment: Appointment) => ({
						...appointment,
						id: appointment._id,
						name: appointment.service_name,
						startDateTime: DateTime.fromMillis(appointment.from).toISO(),
						endDateTime: DateTime.fromMillis(appointment.to).toISO(),
						type: 'appointment',
						color: categories.find(category => category._id === appointment.id_category)?.color
					}))

				const taskEvents = tasks
					.filter(task => (selectedEmployee ? task.assignedBy._id === selectedEmployee : true))
					.map((task: Task) => ({
						...task,
						id: task._id,
						name: task.taskName,
						allDay: true,
						startDateTime: DateTime.fromISO(task.taskDueDate).toISO(),
						endDateTime: DateTime.fromISO(task.taskDueDate).plus({ hours: 1 }).toISO(),
						type: 'task',
						color: taskColors[task.taskType]
					}))

				const allCustomEvents = addCustomEvents(customEvents)
				setEvents([...appointmentEvents, ...taskEvents, ...allCustomEvents])
				setIsLoading(false)
			} catch (error) {
				console.error('Error fetching data: ', error)
				setIsLoading(false)
			}
		}

		fetchData()
	}, [forceRefresh, auth.companyId, auth.is_adminUser, selectedEmployee])

	useEffect(() => {
		if (socket === null) return

		socket.on('Appointment recieved', ({ appointment }) => {
			const newAppointmentEvent = {
				...appointment,
				id: appointment._id,
				name: appointment.service_name,
				startDateTime: DateTime.fromMillis(appointment.from).toISO(),
				endDateTime: DateTime.fromMillis(appointment.to).toISO(),
				type: 'appointment',
				color: categories.find(category => category._id === appointment.id_category)?.color
			}
			if (appointment.status === AppointmentStatus.CANCELLED) {
				setEvents(prev => prev.filter(event => event.id !== appointment._id))
			} else {
				setEvents(prev => {
					const eventIndex = prev.findIndex(event => event.id === appointment._id)
					if (eventIndex !== -1) {
						setForceRefresh(prev => !prev)
						const updatedEvents = [...prev]
						updatedEvents[eventIndex] = newAppointmentEvent
						return [...updatedEvents]
					} else {
						return [...prev, newAppointmentEvent]
					}
				})
			}
		})
		return () => {
			socket.off('Appointment recieved')
		}
	}, [socket, categories])

	useEffect(() => {
		employeeService.getCompanyEmployees().then(res => setEmployees(res))
	}, [])

	const onUpdate = () => {
		setForceRefresh(prev => !prev)
	}

	if (isLoading) {
		return (
			<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.calendar'))}>
				<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
					<div className="fixed inset-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t(tKey('labels.pleaseWait'))}
						</span>
					</div>
				</div>
			</AppLayout>
		)
	}
	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.calendar'))}>
			{showPopup === 'Event' && isMobile && (
				<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setShowPopup(undefined)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t(tKey('createEvent.headings.addEvent'))}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setShowPopup(undefined)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<CreateOrUpdateEventForm
						eventId={selectedEvent}
						onCreation={() => {
							setForceRefresh(prev => !prev)
							setShowPopup(undefined)
						}}
						onClose={() => setShowPopup(undefined)}
					/>
				</Modal>
			)}
			{showPopup === 'Appointment' && isMobile && (
				<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setShowPopup(undefined)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t(tKey('createEvent.headings.addAppointment'))}
						</h1>
						<div className="md.hidden" />
						<XMarkIcon
							onClick={() => setShowPopup(undefined)}
							className="text-primary max-md.hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<CreateOrUpdateAppointmentForm
						appointmentId={selectedAppointmentId}
						onAddClient={() => setShowPopup('Client')}
						onCreation={() => {
							setForceRefresh(prev => !prev)
							setShowPopup(undefined)
						}}
						onClose={() => setShowPopup(undefined)}
					/>
				</Modal>
			)}
			{showPopup === 'Event' && !isMobile && (
				<CreateOrUpdateEventForm
					eventId={selectedEvent}
					onCreation={() => {
						setForceRefresh(prev => !prev)
						setShowPopup(undefined)
					}}
					onClose={() => setShowPopup(undefined)}
				/>
			)}
			{showPopup === 'Client' && isMobile && (
				<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md.grid max-md.grid-flow-col max-md.auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md.p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setShowPopup('Appointment')}
							className="w-3 h-3 md.hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md.text-center whitespace-nowrap max-md.text-sm font-domine font-bold">
							{t('clients.create.titles.addClient')}
						</h1>
						<div className="md.hidden" />
						<XMarkIcon
							onClick={() => setShowPopup('Appointment')}
							className="text-primary max-md.hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>

					<CreateClient
						setCreatedClient={(id: string) => {
							setCreatedClient(id)
							setForceRefresh(prev => !prev)
						}}
						onCreation={() => setShowPopup('Appointment')}
						onCancel={() => setShowPopup('Appointment')}
					/>
				</Modal>
			)}
			{showPopup === 'Client' && !isMobile && (
				<CreateClientForm
					setCreatedClient={(id: string) => {
						setCreatedClient(id)
						setForceRefresh(prev => !prev)
					}}
					onCreation={() => {
						setForceRefresh(prev => !prev)
						setShowPopup(undefined)
					}}
					onClose={() => setShowPopup('Appointment')}
				/>
			)}
			{showPopup === 'Appointment' && (
				<CreateOrUpdateAppointmentForm
					appointmentId={selectedAppointmentId}
					createdClient={createdClient}
					onAddClient={() => setShowPopup('Client')}
					onCreation={() => {
						setForceRefresh(prev => !prev)
						setShowPopup(undefined)
					}}
					onClose={() => setShowPopup(undefined)}
				/>
			)}
			<ReactCalendar
				view={view}
				employees={employees}
				selectedEmployee={selectedEmployee}
				setSelectedEmployee={setSelectedEmployee}
				setView={setView}
				currentDate={currentDate}
				setCurrentDate={setCurrentDate}
				openAppointmentPopup={() => {
					setSelectedAppointmentId(undefined)
					setShowPopup('Appointment')
				}}
				onAppointmentClick={(appointmentId: string) => {
					setShowPopup('Appointment')
					setSelectedAppointmentId(appointmentId)
				}}
				refetch={() => setForceRefresh(prev => !prev)}
				openEventPopup={() => {
					setSelectedEvent(undefined)
					setShowPopup('Event')
				}}
				onEventClick={(eventId: string) => {
					setSelectedEvent(eventId)
					setShowPopup('Event')
				}}
				events={events}
				onUpdate={onUpdate}
			/>
			{showPopup && <div className="fixed inset-0 bg-[#00000099] transition-opacity z-[99]" />}
		</AppLayout>
	)
}
