export enum Language {
	EN = 'en',
	DE = 'de'
}

export const RE_DIGIT = new RegExp(/^\d+$/)

export enum UserSource {
	STYLINGCORNER = 'stylingCorner',
	CRM = 'crm'
}

export enum InsuranceType {
	YES = '1',
	NO = '0'
}

export enum Required {
	YES = 'yes',
	NO = 'no'
}

export enum AppointmentStatus {
	COMPLETED = 'abschluss',
	CONFIRMED = 'bearbeitung',
	PENDING = 'buchung_termin',
	CANCELLED = 'canceled',
	CHECKIN = 'check_in',
	PENDENT = 'pendent'
}

export const mappedAppointmentStatuses = {
	[AppointmentStatus.COMPLETED]: 'Abgeschlossen',
	[AppointmentStatus.CONFIRMED]: 'In Bearbeitung',
	[AppointmentStatus.PENDING]: 'Termin gebucht',
	[AppointmentStatus.CANCELLED]: 'Storniert',
	[AppointmentStatus.CHECKIN]: 'Check-In',
	[AppointmentStatus.PENDENT]: 'Pendent'
}

export const mappedInsuranceTypes = {
	[InsuranceType.YES]: 'Ja',
	[InsuranceType.NO]: 'Nein'
}

export const mappedSources = {
	[UserSource.STYLINGCORNER]: 'Styling Corner',
	[UserSource.CRM]: 'CRM'
}

export enum Status {
	ACTIVE = 'aktiv',
	INACTIVE = 'inaktiv'
}

export enum TaskStatus {
	NOTSTARTED = 'not started',
	INPROGRESS = 'in progress',
	COMPLETED = 'completed'
}

export const mappedStatuses = {
	[Status.ACTIVE]: 'Aktiv',
	[Status.INACTIVE]: 'Inaktiv'
}

export const mappedRequiredTypes = {
	[Required.YES]: 'Ja',
	[Required.NO]: 'Nein'
}

export const mappedTaskStatus = {
	[TaskStatus.NOTSTARTED]: 'Backlog',
	[TaskStatus.INPROGRESS]: 'In Progress',
	[TaskStatus.COMPLETED]: 'Completed'
}

export const germanToEnglishDays = {
	Montag: 'Monday',
	Dienstag: 'Tuesday',
	Mittwoch: 'Wednesday',
	Donnerstag: 'Thursday',
	Freitag: 'Friday',
	Samstag: 'Saturday',
	Sonntag: 'Sunday'
} as { [day: string]: string }
