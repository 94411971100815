import axios from 'services/axios'

const getCompanyEmployees = (): Promise<Employee[]> => {
	return axios.get('auth/company-employees').then(response => response.data)
}

const getEmployeeById = (employeeId: string): Promise<Employee> => {
	return axios.get(`/auth/user/${employeeId}`).then(response => response.data)
}

const createEmployee = (data: EmployeeForm) => {
	return axios.post('/auth/employee/signup', data).then(response => response.data)
}

const updateEmployee = (employeeId: string, data: EmployeeForm) => {
	return axios.put(`/auth/user/${employeeId}`, data).then(response => response.data)
}

const employeeService = {
	getCompanyEmployees,
	getEmployeeById,
	createEmployee,
	updateEmployee
}

export default employeeService
